import { createStore, Store } from 'vuex'
import { InjectionKey } from 'vue'
import { TestConfigFile,  } from '@/assets/vis-testing/config'
import { ReportType, Runner } from '@/assets/vis-testing/exec'
import { Analyzer, EventBinding } from '@/assets/vis-testing/analyze'

export interface Suggestion {
  type: string,
  message: string,
  code: string,
  line: number,
}
export interface State {
  backendUrl: string,
  code: string,
  updateCode: string,
  // eslint-disable-next-line
  dataset: any[],
  bindings: EventBinding[],
  testResult: ReportType,
  version: string,
  config: TestConfigFile,
  style: string,
  flag: number,
  progress: number,
  totalRun: number,
  isRunning: boolean
  suggestions: Suggestion[],
  suggestionFlag: number

}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    backendUrl: "http://localhost:3000/",
    code: "",
    updateCode: "",
    dataset: [],
    bindings: [],
    testResult: {
      datasetArray:[],
      interactionArray: [],
      dataConfigArray: [],
      deviceArray:[],
      results: [],
    },
    suggestions: [],
    style: '',
    version: '7.0.0',
    config: new TestConfigFile({}),
    flag: 0,
    progress: 0,
    totalRun: 0,
    isRunning: false,
    suggestionFlag: 0
  },
  mutations: {
    updateCode(state: State, updatedCode: string):void {
      state.code = updatedCode
      const analyzer = new Analyzer(updatedCode)
      const bindings = analyzer.getAllEventBindings()
      state.updateCode = analyzer.code
      state.bindings = bindings
      console.log(bindings)


      setTimeout(() => {
        console.log('test suggestion update')
        state.suggestionFlag ++
       state.suggestions=[{
            type: "overflow",
            message: "This line may suffer from overflow.",
            code: "`a = 1 + 1;`",
            line: 3
        }]}
    , 9000)
    },
    // eslint-disable-next-line
    updateDataset(state: State, updatedDataset: any[]):void {
      state.dataset = updatedDataset
    },
    updateTestResult(state: State, updatedResult: ReportType):void {
      state.testResult = updatedResult
    },
    updateVersion(state: State, updatedVersion: string):void {
      state.version = updatedVersion
    },
    updateConfig(state: State, updatedConfig: TestConfigFile):void{
      state.config = updatedConfig
    },
    updateStyle(state: State, updatedStyle: string):void {
      state.style = updatedStyle
    },
    updateEventBindings(state: State, updatedBindings: EventBinding[]): void {
      state.bindings = updatedBindings
    },
    updateSuggestions(state: State, updatedSuggestions: Suggestion[]): void {
      state.suggestions = updatedSuggestions
    },
    updateUiFlag(state: State):void {
      state.flag ++
      state.version = state.config.dependencies.d3
      state.testResult = {
        datasetArray:[],
        interactionArray: [],
        dataConfigArray: [],
        deviceArray:[],
        results: [],
      }
    },
    startRunningTest(state: State, runs: number):void {
      state.isRunning = true
      state.totalRun = runs
    },
    updateProgress(state: State, progress: number) {
      state.progress = progress
    },
    endRunningTest(state: State):void {
      state.isRunning = false
      state.progress = 0
      state.totalRun = 0
    }
  },
  actions: {

  },
  modules: {
  }
})
