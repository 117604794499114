import * as d3v7 from 'd3'
import * as d3v3 from '../../d3/d3-3.1.0'
import * as sankey from 'd3-sankey'
// import * as d3v5 from 'd3-5.0.0'
const packageMap: Record<string, any> = {
    'd3': {
        '7.0.0': d3v7,
        '3.0.0': d3v3,

    },
    'd3Sankey': {
        '2.0.0': sankey
    }
}

/**
 * Get the running context of external module reliance
 * @param config a package.json-like specification of modules to be used in the program
 * @returns a sheet which resolves the package name and version into real project
 */
const getDependencySheet = function(config: Record<string, string>) {
    const dependencySheet = {} as Record<string, any>
    Object.keys(config).forEach(key => {
        const val = config[key]
        if (key in packageMap) {
            const versions = Object.keys(packageMap[key]).filter(k => k >= val)
            const version = versions.includes(val) ? val : versions[0]
            dependencySheet[key] = packageMap[key][version]
        }
        else {
            console.warn(`No module found: ${key}-${val}`)
        }
    })
    return dependencySheet
}

export {
    getDependencySheet
}