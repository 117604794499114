import OpenAI from "openai";
import { ChatCompletionMessageParam } from "openai/resources";


const MODEL = 'gpt-4-1106-preview' // 'gpt-4-0613'
const FUNC_MODEL = 'gpt-3.5-turbo-0125'
const SECRET_KEY = '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' // sensitive information

/**
 * Query GPT3.5 with a list of messages
 * @param msgs a list of instructions
 * @returns a parsed object from json-based specification
 */

export async function structuredQueryGPT(msgs: ChatCompletionMessageParam[]) {
    const openai = new OpenAI({
        apiKey: SECRET_KEY,
        dangerouslyAllowBrowser: true
    })
    return openai.chat.completions.create({
        messages: msgs,
        model: FUNC_MODEL,
        temperature: 0.2,
        response_format: { "type": "json_object" }
    })
}


export async function freeformQueryGPT(msgs: ChatCompletionMessageParam[]) {
    const openai = new OpenAI({
        apiKey: SECRET_KEY,
        dangerouslyAllowBrowser: true
    })
    return openai.chat.completions.create({
        messages: msgs,
        model: FUNC_MODEL,
        temperature: 0.2,
    })
        
}

