// eslint-disable-next-line
// @ts-nocheck
/**
 * This is the doc comment for analyze.ts
 * @module vis-testing
 * Static Analysis of visualization code.
 */
import * as parser from '@babel/parser'
import traverse from '@babel/traverse'
import generate from '@babel/generator'
import * as babelType from '@babel/types'
import template from '@babel/template'


import { getRandomAlphabet, randintGenerator } from './utils/wheel'
const NAMELEN = 8
const INTERACTIONTYPE = ['d3.brush', 'd3.drag', 'mouseenter', 'mouseover', 'mouseleave', 'click', 'dblclick']
type EventBinding = {
    classNames: string[],
    event: keyof GlobalEventHandlersEventMap | 'd3.brush',
    funcNames?: string[],
    types: Array<keyof SVGElementTagNameMap>  // hints for configuration
}
class Analyzer {
    /**
     * Initialize the code analyzer
     * @param code string of the visualization function
     */
    code: string
    srcCode: string
    get ast() {
        return parser.parse(this.code)
    }
    events: string[]
    bindings: EventBinding[] = []
    constructor(code: string, defaultEvents = INTERACTIONTYPE) {
        this.srcCode = code
        this.code = code
        if (!this.validate()) throw 'Invalid function!'
        this.events = defaultEvents
    }
    /**
     * @returns event bindings for interactive elements
     */
    public getAllEventBindings() {
        const bindings = [] as EventBinding[]
        if (this.bindings.length != 0) return this.bindings
        this.events.forEach(e => {
            switch (e) {
                case 'd3.brush':
                    bindings.push(this.getBrushBinding())
                    break
                case 'd3-drag':
                    bindings.push(this.getDragBinding())
                    break
                default:
                    bindings.push(this.getEventBinding(e))
            }
        })
        this.bindings = bindings
        return bindings
    }
    /**
     * Generate code for triggering events
     */
    public getLine4Event(e: keyof GlobalEventHandlersEventMap | 'd3.brush' | 'd3.drag', param: any = {}, identifier = ''): string {
        const binding = this.bindings.filter(v => v.event === e)[0]
        const classNames = binding.classNames
        const funcName = binding.funcName
        const name = identifier || classNames[randintGenerator(0, classes.length)()]
        switch (e) {
            case 'd3.brush':
                return `d3.select('.${name}').call({${funcName}.move, ${param.toString()})`
            case 'd3-drag':
                return `d3.select('.${name}').call(${funcName})`
            default:
                return `
                const _groupOfElements = d3.selectAll('.${name}')._groups
                const _sizeOfElements = _groupOfElements.length
                const _randomIdx = Math.floor( Math.random() * ( 1 + _sizeOfElements ) ) 
                d3.select(_groupOfElements[_randomIdx]).dispatch('${e}')
             `

        }
    }
    /**
     * Validate the input visualization function
     * @returns whether the code is valid
     */
    protected validate(): boolean {
        // !!! NOT IMPLEMENTED !!!
        return true
    }
    /**
     * Insert class declaration for interactive elements
     * @param e event name
     * @returns 
     */
    protected getEventBinding(e: keyof GlobalEventHandlersEventMap): EventBinding {
        const classNames = []
        const ast = this.ast
        const types = []

        const visitor = {
            // we only check all CallExpressions
            CallExpression(path) {
                // remove comments in the current path
                path.node = babelType.removeComments(path.node)

                if (path.node.callee.property) {
                    if (path.node.callee.property.name === 'on') {
                        const e_info = path.node.arguments[0]

                        if (e_info.value === e && e_info.type === "StringLiteral") {
                            const temp_class_name = `${e}_${getRandomAlphabet(NAMELEN)}`
                            classNames.push(temp_class_name)
                            const temp_type_name = 'circle'
                            types.push(temp_type_name)   // !!! to implementation

                            const code_string = generate(path.node).code

                            if (code_string.slice(-1) === ';') {
                                const code_string_with_class_ast = template.statement.ast`${code_string.slice(0, code_string.length - 1)}.classed('${temp_class_name}', true);`
                                path.replaceWith(code_string_with_class_ast)
                            }
                            else {
                                const code_string_with_class_ast = template.statement.ast`${code_string}.classed('${temp_class_name}', true);`
                                path.replaceWith(code_string_with_class_ast)
                            }
                        }
                    }
                }
            }
        }

        traverse(ast, visitor)

        this.code = generate(ast).code
        return {
            event: e,
            classNames: classNames,
            elementTypes: types,
            funcNames: 'on'
        }
    }

    /**
     * Insert class declaration for interactive elements
     * @assumes the alias for svg is named svg
     * @assumes the brush is declared with a variable
     * @assumes there is only one brush
     */
    protected getBrushBinding(): EventBinding {
        let brush_func_name;
        let brush_func_name_end;
        const ast = this.ast
        traverse(ast, {
            enter(path) {
                if (path.node.name === 'brush' && checkD3Brush(path)) {
                    brush_func_name = path.getStatementParent().node.declarations[0].id.name
                    brush_func_name_end = path.getStatementParent().node.declarations[0].end
                }
            }
        }
        )
        const class_name = `brush_${getRandomAlphabet(NAMELEN)}`
        traverse(ast, {
            enter(path) {
                if (path.node.name == brush_func_name && path.node.end > brush_func_name_end && check_call(path)) {
                    path.getStatementParent().replaceWithSourceString(`svg.append('g').classed('${class_name}', true).call(${brush_func_name});`)
                }
            }
        }
        )
        this.code = generate(ast).code
        const classNames = brush_func_name ? [class_name] : []
        return {
            event: 'd3.brush',
            classNames: classNames,
            elementTypes: ['g'],
            funcName: [brush_func_name]
        }
    }
    /**
     * Insert class declaration for draggable elements
     * @warns not implemented
    */
    protected getDragBinding(): EventBinding {
        // !!! NOT IMPLEMENTED !!!
        const drag_func_name = 'drag'
        return {
            event: 'd3.drag',
            classNames: [],
            funcName: drag_func_name
        }
    }
}

const check_call = function (path): boolean {
    try {
        return path.getStatementParent().node.expression.callee.property.name === 'call'
    } catch (error) {
        return false
    }
}

/**
 * check the co-existence of d3 and brush
 */
const checkD3Brush = function (path): boolean {
    const t = path.findParent((p) => {
        if (p.isMemberExpression()) {
            try {
                if (p.node.object.name === 'd3') {
                    return true
                }
                return false
            } catch (error) {
                return false
            }
        }
        return false
    })
    return (t !== undefined && t !== null)
}

export {
    Analyzer,
    EventBinding,
}
