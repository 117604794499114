import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = { class: "previewImageWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_list_item = _resolveComponent("a-list-item")!

  return (_openBlock(), _createBlock(_component_a_list_item, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_card, { onClick: _ctx.clickOnInstance }, {
          cover: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_image, {
                width: "100%",
                class: "previewImage",
                alt: `Thumbnail of test result No. ${_ctx.index}`,
                src: _ctx.record.thumbnail,
                fallback: "./img/fallback.png"
              }, null, 8, ["alt", "src"])
            ])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.record.result.filter(v => v.value != 0), (error, eid) => {
              return (_openBlock(), _createBlock(_component_a_tag, {
                color: error.value?'red':'green',
                key: `tag_${eid}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(error.error.replaceAll('_', ' ')), 1)
                ]),
                _: 2
              }, 1032, ["color"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}