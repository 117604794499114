/**
 * This is the doc comment for detect.ts.
 * @module vis-testing
 * Checker for the output SVG.
 */

import * as d3Select from 'd3-selection'
import { ErrorDetectConfig, ErrorType, mapOfErrorAbbr } from "./config"
import { EventBinding } from "./analyze"
const canvasId = 'canvas-container'
/**
 * Errors to detect for an svg.
 */
interface SVGChecklist {
    text_overlap?: boolean,
    mark_overlap?: boolean,
    text_mark_overlap?: boolean,
    contrast?: boolean,
    small_text?: boolean,
    small_mark?: boolean,
    undesired_value?: boolean,
    overflow?: boolean,
    thumbnail?: string
}
/**
 * Checker for the output SVG
 */
class ChartDetector {
    node: SVGElement
    reasonSvg: SVGElement
    svgstr: string
    height: number
    width: number
    needReason: boolean
    needAllReasons: boolean
    config: ErrorDetectConfig
    canvas: HTMLCanvasElement
    thumbnail = ""
    eventBindings: EventBinding[]
    textBBox: DOMRect[]
    svgBBox: DOMRect
    textData = new Uint8ClampedArray()
    thumbnailOn: boolean
    originNode: SVGElement
    /**
     * Construct a parser and analyzer to detect errors in a given svg.
     * @param svgstr svg string
     * @param node svg element on a page
     */
    constructor(svgstr: string, node: SVGElement | undefined, needReason = false, needAllReasons = false, thumbnailOn = true, config: ErrorDetectConfig = new ErrorDetectConfig({}), eventBindings: EventBinding[] = []) {
        this.svgstr = svgstr
        this.needAllReasons = needAllReasons
        this.needReason = needReason
        this.config = config
        this.eventBindings = eventBindings
        // console.log(node)
        if (node) {
            this.originNode = node
            this.node = d3Select.select(node.parentElement)
              .append('svg')
              .classed('fakeSvg', true)
              .attr('xlmns', 'http://www.w3.org/2000/svg')
              .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
              .html(d3Select.select(node).html()).node() as SVGElement
            cloneAttributes(this.node, node)
        }
        else {
            // !!! need modification in node environment
            const ele = document.createElement('svg') as unknown as SVGElement
            ele.style.visibility = 'hidden'
            ele.innerHTML = svgstr
            this.node = ele
            this.originNode = ele
        }
        this.height = Number(this.node.getAttribute('height'))
        this.width = Number(this.node.getAttribute('width'))
        this.canvas = document.createElement('canvas')
        this.canvas.height = this.height
        this.canvas.width = this.width
        const parent = d3Select.select(this.node.parentElement)
        parent.selectAll('.reason-layer').remove()
        const reasonSvgEle = parent
            .append('svg')
            .style('position', 'absolute')
            .style('x', '0px')
            .style('y', '0px')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('viewbox', `0 0 ${this.width} ${this.height}`)
            .attr('xlmns', 'http://www.w3.org/2000/svg')
            .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
            .classed('reason-layer', true)
            .style('pointer-events', 'none')
        this.reasonSvg = reasonSvgEle.node() as SVGElement

        reasonSvgEle.append('g').classed('overflow', true).classed('error-layer', true)
        reasonSvgEle.append('g').classed('textOverlap', true).classed('error-layer', true)
        reasonSvgEle.append('g').classed('textMarkOverlap', true).classed('error-layer', true)
        reasonSvgEle.append('g').classed('markOverlap', true).classed('error-layer', true)
        reasonSvgEle.append('g').classed('smallText', true).classed('error-layer', true)
        reasonSvgEle.append('g').classed('smallMark', true).classed('error-layer', true)
        reasonSvgEle.append('g').classed('contrast', true).classed('error-layer', true)
        reasonSvgEle.append('g').classed('undesiredValue', true).classed('error-layer', true)

        
        traverseDOMTree2RemoveInvisible(this.node)

        const svg = d3Select.select(this.node)
        const textEles = svg.selectAll('text') as any
        this.textBBox = this.getBbox(textEles)
        this.svgBBox = this.node.getBoundingClientRect()

        this.thumbnailOn = thumbnailOn

    }
    /**
     * Return to the initial status after running interaction simulation
     */
    public return2init(): void {
        this.node.innerHTML = this.svgstr
    }

    static highlightError(svg: d3Select.Selection<any, any, any, any>, errorName: ErrorType | 'all'): void {
        if (errorName == 'all') {
            svg.selectAll('.error-layer').style('visibility', 'visible')
            return
        }
        svg.selectAll('.error-layer').style('visibility', 'hidden')
        const layerName = mapOfErrorAbbr.get(errorName);
        svg.select(`.${layerName}`).style('visibility', 'visible')
    }

    /**
     * Run the test based on output svg.
     */
    public async runTest(): Promise<SVGChecklist> {
        const textSvg = this.node.cloneNode(true) as SVGElement
        textSvg.removeAttribute('id')
        textSvg.setAttribute("style", "background: none;")
        traverseDOMTree2Obtain(textSvg, 'text')

        return Promise.all([this.createCanvas(textSvg), this.createCanvas(this.node.cloneNode(true) as SVGElement)]).then(([textCanvas, allCanvas]) => {
            const textCtx = textCanvas.getContext('2d') as CanvasRenderingContext2D
            this.textData = textCtx.getImageData(0, 0, this.width, this.height).data
            if (this.thumbnailOn) {
                this.thumbnail = allCanvas.toDataURL("image/png")
            }
        }).then(() => {
            d3Select.select(`.${canvasId}`).html('')
            // d3Select.select(`.reason-layer`).html('')
            const text_text_occlusion = this.check_annotation_occlusion()
            let text_mark_occlusion = false
            let text_mark_contrast = false
            const small_mark_size = this.config.smallText.isOn ? this.check_small_mark_size() : false

            const task_cross_overlap = this.check_text_mark_occlusion().then(d => {
                text_mark_occlusion = this.config.crossOverlap.isOn ? d : false
            })
            const task_contrast = this.check_text_mark_contrast().then(d => {
                text_mark_contrast = this.config.contrast.isOn ? d : false
            })
            const mark_mark_occlusion = this.config.markOverlap.isOn ? this.check_mark_mark_occlusion() : false
            const small_text_size = this.config.smallText.isOn ? this.check_small_text_size() : false
            const invalid_value = this.config.invalidValue.isOn ? this.check_invalid_value() : false
            const overflow = this.config.overflow.isOn ? this.check_content_overflow() : false

            d3Select.select(`.${canvasId}`).html('')
            return Promise.all([task_cross_overlap, task_contrast]).then(v => {
                d3Select.select(this.node.parentElement).select('.fakeSvg').remove()
                return {
                    undesired_value: invalid_value, // no threshold, highlight implemented (not tested)
                    small_mark: small_mark_size, // threshold implemented, highlight implemented
                    small_text: small_text_size, // threshold implemented, highlight implemented, not work correctly
                    text_mark_overlap: text_mark_occlusion, // no threshold, highlight implemented
                    contrast: text_mark_contrast, // threshold implemented, highlight implemented, not work correctly
                    mark_overlap: mark_mark_occlusion, // threshold implemented, highlight implemented
                    text_overlap: text_text_occlusion, // threshold implemented, highlight implemented
                    overflow: overflow, // threshold implemented, highlight implemented
                }
            })
        })
    }

    private getBbox(group: d3Select.Selection<any, any, any, any>): DOMRect[] {
        const bboxes: DOMRect[] = []
        group.each(function () {
            const bbox = this.getBoundingClientRect()
            bboxes.push(bbox)
        })
        return bboxes
    }

    /**
     * Log the running time of a function with the input args
     * @param func 
     * @param args 
     */
    public performanceTest<T, D>(func: (args: D) => T, args: D): void {
        const startTime = new Date().getTime()
        func(args)
        const endTime = new Date().getTime()
        const duration = endTime - startTime
        console.log('PERFORMANCE TEST', '\n', func.name, duration)
    }

    /**
     * Deteck overlap of bounding boxes of given elements.
     * @param bboxes 
     * @param threshold threshold of overlapping
     * @param overflow check overflow or occlusion
     * @param highlightColor if not overflow, specify the highlight color manually
     * @returns whether there exists occlusion in the bounding box
     */
    private check_bounding_box_occlusion(bboxes: DOMRect[], threshold = 0.05, detectedIssue = "overflow", highlightColor = 'none'): boolean {

        let reasonLayer = false as any;
        if (detectedIssue == "overflow") {
            reasonLayer = d3Select.select(this.reasonSvg).select(".overflow")
        }
        else if (detectedIssue == "textOverlap") {
            reasonLayer = d3Select.select(this.reasonSvg).select(".textOverlap")
        }
        else if (detectedIssue == "markOverlap") {
            reasonLayer = d3Select.select(this.reasonSvg).select(".markOverlap")
        }


        let flag = false
        for (let i = 0; i < bboxes.length; i++) {
            if (flag && (!this.needAllReasons)) break
            for (let j = i + 1; j < bboxes.length; j++) {
                const a = bboxes[i]
                const b = bboxes[j]

                // use the smaller value as the threshold
                const area = a.width * a.height < b.width * b.height ? a.width * a.height : b.width * b.height

                const x_left = Math.max(a.left, b.left)
                const x_right = Math.min(a.right, b.right)
                const y_top = Math.max(a.top, b.top)
                const y_bottom = Math.min(a.bottom, b.bottom)
                // detect overlap
                if ((x_right > x_left) && (y_bottom > y_top)) {
                    // apply threshold
                    if (detectedIssue === "overflow") {
                        // check if entire overflow still work

                        if (Math.abs(x_right - x_left) * Math.abs(y_bottom - y_top) < (1 - threshold) * area || x_right - x_left < 0 || y_bottom - y_top < 0) {
                            if (this.needReason) {
                                this.highlight_error_with_rect(a, reasonLayer, this.config.overflow.highlightColor)
                            }
                            flag = true
                        }
                    }
                    else {
                        if (Math.abs(x_right - x_left) * Math.abs(y_bottom - y_top) > threshold * area) {
                            if (this.needReason) {
                                this.highlight_error_with_rect(a, reasonLayer, highlightColor)
                                this.highlight_error_with_rect(b, reasonLayer, highlightColor)
                            }
                            flag = true
                        }
                    }
                }
            }
        }
        return flag
    }

    /**
     * Check annotation-annotation overlap
     * Current implementation roughly retrieves all text elements in the svg and test whether their bounding box overlaps with each other
     * @returns whether the annotations overlap with each other
     */
    protected check_annotation_occlusion(): boolean {
        // !!! need modification: use occcupancy map for annotation elements only
        // const svg = d3Select.select(this.node)
        // const textEles = svg.selectAll('text') as any
        // const bboxes = this.getBbox(textEles)
        const result = this.check_bounding_box_occlusion(this.textBBox, this.config.textOverlap.threshold, "textOverlap", this.config.textOverlap.highlightColor)
        return result
    }

    /**
     * Check mark
     * Current implementation roughly retrieves all text elements in the svg and test whether their bounding box overlaps with each other
     * @returns whether the annotations overlap with each other
     */
    protected check_mark_mark_occlusion(): boolean {
        const svg = d3Select.select(this.node)
        const allMarkEles = svg.selectAll("*") as d3Select.Selection<any, any, any, any>
        const markEles = allMarkEles.filter(function (this: Element) { return !(['g', 'svg', 'clipPath', 'text'].includes(this.nodeName)) });
        const bboxes = this.getBbox(markEles)
        const result = this.check_bounding_box_occlusion(bboxes, this.config.markOverlap.threshold, "markOverlap", this.config.markOverlap.highlightColor)
        return result

    }

    /**
     * Check small mark size
     * @returns whether there exist small marks with extremely tiny size to interact with
     */
    protected check_small_mark_size(): boolean {
        const reasonLayer = d3Select.select(this.reasonSvg).select('.smallMark')
        const svg = d3Select.select(this.node)
        let result = false
        // const explainations = [] as DOMRect[]
        this.eventBindings.forEach(binding => {
            if (binding.event == 'd3.brush') return
            binding.classNames.forEach((alias) => {
                const boxes = this.getBbox(svg.selectAll(`.${alias}`))
                boxes.forEach((box) => {
                    const size = box.width * box.height
                    if (size < this.config.smallMark.threshold) {
                        result = true
                        // explainations.push(box)
                        if (this.needReason) {
                            this.highlight_error_with_rect(box, reasonLayer, this.config.smallMark.highlightColor)
                        }
                    }
                })
            })
        })

        return result
    }


    /**
     * Create a canvas-version of the given svg element
     * @param svg svg node
     * @returns the canvas element with the given svg content drawn
     */
    private async createCanvas(svg: SVGElement): Promise<HTMLCanvasElement> {
        svg.setAttribute("xmlns", "http://www.w3.org/2000/svg")
        svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink")
        const canvas = d3Select
            .select(`.${canvasId}`)  // !!! dependent on the global element `canvasId`
            .append('canvas')
            .attr('height', this.height)
            .attr('width', this.width)
            .node() as HTMLCanvasElement
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
        const outerHtml = svg.outerHTML
        // !!! need modification: remove reliance on the browser window
        const blob = new Blob([outerHtml], { type: 'image/svg+xml;chartset=utf-8' })
        const blobURL = window.URL.createObjectURL(blob) 
        return loadImage(blobURL).then((image: HTMLImageElement) => {
            ctx.drawImage(image, 0, 0, this.width, this.height)
            return canvas
        }).catch((err) => {
            console.log('error in loading image\n', err)
            return canvas
        })
    }

    /**
     * Convert hex color to rgb
     * @param hex hex color string without alpha, e.g., #0033ff
     * @returns rgb color
     */
    protected hexToRgb(hex: string) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    /**
     * Check mark-annotation overlap
     * @returns whether the data marks occlude with annotations
     */
    protected async check_label_mark_occulusion(): Promise<boolean> {
        const graphicalSvg = this.node.cloneNode(true) as SVGElement
        graphicalSvg.removeAttribute('id')
        graphicalSvg.setAttribute("style", "background: none;")
        d3Select.select(graphicalSvg).selectAll('text').remove()
        const textSvg = this.node.cloneNode(true) as SVGElement
        textSvg.removeAttribute('id')
        textSvg.setAttribute("style", "background: none;")
        traverseDOMTree2Obtain(textSvg, 'text')

        const color = this.hexToRgb(this.config.crossOverlap.highlightColor)

        return Promise.all([
            this.createCanvas(this.node),
            this.createCanvas(graphicalSvg),
            this.createCanvas(textSvg)
        ]).then(([explainCanvas, graphicalCanvas, textCanvas]) => {
            // copy a screenshot
            const sourceImageData = explainCanvas.toDataURL("image/png")
            const destCanvasContext = this.canvas.getContext('2d')
            const destinationImage = new Image()
            destinationImage.onload = function () {
                destCanvasContext!.drawImage(destinationImage, 0, 0)
            }
            destinationImage.src = sourceImageData
            const explainCtx = explainCanvas.getContext('2d') as CanvasRenderingContext2D
            const graphicalCtx = graphicalCanvas.getContext('2d') as CanvasRenderingContext2D
            const textCtx = textCanvas.getContext('2d') as CanvasRenderingContext2D
            // this.thumbnail = ""
            if ((!explainCtx) || (!graphicalCtx) || (!textCtx)) {
                console.warn('Failed to create a canvas context.')
                return true
            }
            const graphicalData = graphicalCtx.getImageData(0, 0, this.width, this.height).data
            const textData = textCtx.getImageData(0, 0, this.width, this.height).data
            const explainDataObj = explainCtx.getImageData(0, 0, this.width, this.height)
            const explainData = explainDataObj.data
            let countOcclusion = 0
            for (let i = 0; i < textData.length; i += 4) {
                // const r = graphicalData[i]
                // const g = graphicalData[i+1]
                // const b = graphicalData[i+2]
                // const a = graphicalData[i+3]
                // const tr = textData[i]
                // const tg = textData[i+1]
                // const tb = textData[i+2]
                // const ta = textData[i+3]
                const isFilled = graphicalData[i] || graphicalData[i + 1] || graphicalData[i + 2] || graphicalData[i + 3]
                const isFilledText = textData[i] || textData[i + 1] || textData[i + 2] || textData[i + 3]
                // if (isFilledText) console.log(i)
                const overlap = isFilled && isFilledText
                if (overlap) {
                    countOcclusion += 1
                    if (this.needAllReasons || this.needReason) {
                        // explainData[i] = 255 -> useless
                        if (color === null) {
                            explainData[i] = 0
                            explainData[i + 2] = 255
                            explainData[i + 3] = 0
                        }
                        else {
                            explainData[i] = color.r
                            explainData[i + 2] = color.g
                            explainData[i + 3] = color.b
                        }

                    }
                    if (!this.needAllReasons) {
                        break;
                    }
                }
            }

            if (this.needReason || this.needAllReasons) {
                explainCtx.putImageData(explainDataObj, 0, 0)
            }
            // graphicalCanvas.remove()
            // textCanvas.remove()
            // explainCanvas.remove()
            return countOcclusion > 0
        })
    }

    /**
    * Check overlap between texts and marks
    * @returns whether the overlap between texts and marks exceed the threshold
    * @author 
    */
    protected async check_text_mark_occlusion(): Promise<boolean> {
        // function to calculate the luminance for a RGB color

        const reasonLayer = d3Select.select(this.reasonSvg).select(".textMarkOverlap")
        const graphicalSvg = this.node.cloneNode(true) as SVGElement
        graphicalSvg.removeAttribute('id')
        // no need to set background color as none since text and background can also have low contrast ratio
        d3Select.select(graphicalSvg).selectAll('text').remove()


        return Promise.all([
            this.createCanvas(graphicalSvg),
        ]).then(([graphicalCanvas]) => {
            const graphicalCtx = graphicalCanvas.getContext('2d') as CanvasRenderingContext2D
            // this.thumbnail = ""
            if ((!graphicalCtx)) {
                console.warn('Failed to create a canvas context.')
                return true
            }
            const graphicalData = graphicalCtx.getImageData(0, 0, this.width, this.height).data

            const list_overlap_ratio = this.textBBox.map((BBox, i) => {

                // the bounding box range needs correction
                const corrected_x_0 = Math.round(BBox.left - this.svgBBox.x)
                const corrected_y_0 = Math.round(BBox.top - this.svgBBox.y)
                const corrected_x_1 = Math.round(BBox.right - this.svgBBox.x)
                const corrected_y_1 = Math.round(BBox.bottom - this.svgBBox.y)

                // handle bbox outside svg and slightly reduce the area of checking (avoid rounding errors)
                const x_0 = corrected_x_0 > 0 ? (corrected_x_0 < this.width ? corrected_x_0 + 1 : this.width) : 0
                const y_0 = corrected_y_0 > 0 ? (corrected_y_0 < this.width ? corrected_y_0 + 1 : this.width) : 0
                const x_1 = corrected_x_1 > 0 ? (corrected_x_1 < this.width ? corrected_x_1 - 1 : this.width) : 1
                const y_1 = corrected_y_1 > 0 ? (corrected_y_1 < this.width ? corrected_y_1 - 1 : this.width) : 1

                let overlap_count = 0
                let text_count = 0
                // check text pixels and background pixel inside the BBox 
                for (let i = x_0; i <= x_1; i++) {
                    for (let j = y_0; j <= y_1; j++) {
                        const idx = get_pixel_index(i, j, this.width)
                        const isFilled = graphicalData[idx] || graphicalData[idx + 1] || graphicalData[idx + 2] || graphicalData[idx + 3]
                        const isFilledText = this.textData[idx] || this.textData[idx + 1] || this.textData[idx + 2] || this.textData[idx + 3]

                        if (isFilledText) {
                            text_count += 1
                            if (isFilled) {
                                overlap_count += 1
                            }
                        }
                    }
                }

                if ((overlap_count + 1) / (text_count + 1) > this.config.crossOverlap.threshold) {
                    if (this.needReason) {
                        this.highlight_error_with_rect(BBox, reasonLayer, this.config.crossOverlap.highlightColor)
                    }
                }


                return (overlap_count + 1) / (text_count + 1) > this.config.crossOverlap.threshold
            })
            return list_overlap_ratio.some(d => d)
        })
    }

    /**
  * Check the color contrast level between text and its surroundings
  * @returns whether exists the lake of color contrast between text and its surroundings
  * @author Zixin
  */
    protected async check_text_mark_contrast(): Promise<boolean> {
        // function to calculate the luminance for a RGB color
        function luminance(r: any, g: any, b: any) {
            const a = [r, g, b].map(function (v) {
                v /= 255;
                return v <= 0.03928
                    ? v / 12.92
                    : Math.pow((v + 0.055) / 1.055, 2.4);
            });
            return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
        }

        const reasonLayer = d3Select.select(this.reasonSvg).select(".contrast")
        const graphicalSvg = this.node.cloneNode(true) as SVGElement
        graphicalSvg.removeAttribute('id')
        // no need to set background color as none since text and background can also have low contrast ratio
        d3Select.select(graphicalSvg).selectAll('text').remove()


        return Promise.all([
            this.createCanvas(graphicalSvg)
        ]).then(([graphicalCanvas]) => {
            const graphicalCtx = graphicalCanvas.getContext('2d') as CanvasRenderingContext2D
            // this.thumbnail = ""
            if ((!graphicalCtx)) {
                console.warn('Failed to create a canvas context.')
                return true
            }
            const graphicalData = graphicalCtx.getImageData(0, 0, this.width, this.height).data

            const list_contrast_ratio = this.textBBox.map((BBox, i) => {

                // the bounding box range needs correction
                const corrected_x_0 = Math.round(BBox.left - this.svgBBox.x)
                const corrected_y_0 = Math.round(BBox.top - this.svgBBox.y)
                const corrected_x_1 = Math.round(BBox.right - this.svgBBox.x)
                const corrected_y_1 = Math.round(BBox.bottom - this.svgBBox.y)

                // handle bbox outside svg and slightly reduce the area of checking (avoid rounding errors)
                const x_0 = corrected_x_0 > 0 ? (corrected_x_0 < this.width ? corrected_x_0 + 1 : this.width) : 0
                const y_0 = corrected_y_0 > 0 ? (corrected_y_0 < this.width ? corrected_y_0 + 1 : this.width) : 0
                const x_1 = corrected_x_1 > 0 ? (corrected_x_1 < this.width ? corrected_x_1 - 1 : this.width) : 1
                const y_1 = corrected_y_1 > 0 ? (corrected_y_1 < this.width ? corrected_y_1 - 1 : this.width) : 1

                const luminance_text = []
                const luminance_bg = []
                // check text pixels and background pixel inside the BBox 
                for (let i = x_0; i <= x_1; i++) {
                    for (let j = y_0; j <= y_1; j++) {
                        const idx = get_pixel_index(i, j, this.width)

                        if (this.textData[idx] || this.textData[idx + 1] || this.textData[idx + 2] || this.textData[idx + 3]) {
                            // console.log(textData[idx], textData[idx + 1], textData[idx + 2], textData[idx + 3], "text")
                            luminance_text.push(luminance(this.textData[idx], this.textData[idx + 1], this.textData[idx + 2]))
                        }
                        // if the pixel is not occupied by texts -> add one to luminance_bg
                        else {

                            if (graphicalData[idx] || graphicalData[idx + 1] || graphicalData[idx + 2] || graphicalData[idx + 3]) {
                                luminance_text.push(luminance(this.textData[idx], this.textData[idx + 1], this.textData[idx + 2]))
                            }
                            else {
                                luminance_bg.push(1)
                            }

                            luminance_bg.push(luminance(graphicalData[idx], graphicalData[idx + 1], graphicalData[idx + 2]))
                        }
                    }
                }

                // console.log(luminance_text, luminance_bg, "lum")
                // return the contrast ratio of the text element
                if (luminance_text.length == 0 || luminance_bg.length == 0) {
                    return false
                }

                const ave_luminance_text = (luminance_text.reduce((a, b) => a + b) / luminance_text.length)

                const ave_luminance_bg = (luminance_bg.reduce((a, b) => a + b) / luminance_bg.length)

                // always smaller luminance is divided by the larger one
                const contrast_ratio = ave_luminance_bg > ave_luminance_text ? (ave_luminance_text + 0.05) / (ave_luminance_bg + 0.05) : (ave_luminance_bg + 0.05) / (ave_luminance_text + 0.05)

                // https://www.w3.org/TR/WCAG20-TECHS/G18.html
                if (contrast_ratio > this.config.contrast.threshold) {
                    if (this.needReason) {
                        this.highlight_error_with_rect(BBox, reasonLayer, this.config.contrast.highlightColor)
                    }
                }


                return contrast_ratio > this.config.contrast.threshold
            })
            return list_contrast_ratio.some(d => d)
        })
    }


    /**
     * Check if currentElement is outside svg
     * @returns 
     * @author Zixin
     */
    protected traverseDOMTree2Check(currentElement: Element, svg: Element, flag = false, threshold = 0.05): boolean {
        // svg has to be at the second place to make sure it will not be drawn
        // <g>s are skipped since they are flexible containers instead of the root cause of overflow
        let result = (currentElement.nodeName == 'g') || (currentElement.nodeName == 'clipPath') ? false : this.check_bounding_box_occlusion([currentElement.getBoundingClientRect(), svg.getBoundingClientRect()], threshold, "overflow")

    

        Array.from(currentElement.children).forEach((e) => {
            const childResult = this.traverseDOMTree2Check(e, svg, result || flag, threshold)  // make sure clipPath is maintained
            result = result || childResult  // make sure any paths containing the text element is maintained
        })
        return result
    }


    /**
     * Check the element overflows the boundary
     * @returns whether exists one graphical element has exceeded the boundary
     * @author Zixin
     */
    protected check_content_overflow(): boolean {
        // 1) clippath: check existence of "clip-path" attribute -> remove the reliance -> compare the original canvas with the new one without clippath
        // 2) overflow of svg: check bounding box position with boundaries
        // 3) overflow of 
        const svg = d3Select.select(this.node) as any// get the svg
        let flag = false


        flag = this.traverseDOMTree2Check(svg._groups[0][0], svg._groups[0][0], false, this.config.overflow.threshold)

        // if (this.needReason) {
        //    handled in check_bounding_box_occlusion(), may need updates in the future -> split check_bounding_box_occlusion() to two functions
        // }
        return flag
    }

    /**
     * Small text size violation, sometimes cannot get fontsize
     * @returns 
     * @author Zixin
     */
    protected check_small_text_size(): boolean {
        const svg = d3Select.select(this.node)
        const reasonLayer = d3Select.select(this.reasonSvg).select('.smallText')


        const textEles = svg.selectAll('text') as any
        const SmallTThre = this.config.smallText.threshold
        let count = 0
        let result = false
        // console.log(textEles)

        for (let i = 0; i < textEles._groups[0].length; i++) {
            // console.log(textEles._groups[0][i].style.fontSize)
            // const size = Number(textEles._groups[0][i].style.fontSize[0])
            const size = Number(window.getComputedStyle(textEles._groups[0][i], null).getPropertyValue('font-size').replace("px", ""))

            if (typeof (SmallTThre) === 'number') {
                if (size < SmallTThre) {
                    count += 1
                    result = true

                    if (this.needReason) {
                        this.highlight_error_with_rect(textEles._groups[0][i].getBoundingClientRect(), reasonLayer, this.config.smallText.highlightColor, 0.2)
                    }
                }
            }
        }

        // console.log(result)

        return result
    }

    /**
     * Draw the rect for highlighting errors
     * @param highlightElement the element to be highlighted
     * @param reasonLayer the <g> to add rects
     * @param fillColor the color of the rects
     * @param opacity the opacity of the rects
     * @returns 
     */
    private highlight_error_with_rect(highlightElementBBox: DOMRect, reasonLayer: any, fillColor = "none", opacity = 0.2) {
        // const curChildBBox = highlightElement.getBoundingClientRect()
        reasonLayer.append('rect')
            .attr('x', highlightElementBBox.x - this.svgBBox.x)
            .attr('y', highlightElementBBox.y - this.svgBBox.y)
            .attr('width', highlightElementBBox.width)
            .attr('height', highlightElementBBox.height)
            .style('fill', fillColor).attr('opacity', opacity)
    }


    /**
     * Invalid value in element text or attribute
     * @param tagName tag name of elements for detecting undesired words
     * @param keyword the undesired words
     * @param parentElement the parent element where the undesired words are
     */
    private check_single_undesired_value(parentElement:SVGElement,tagName = "text", keyword = "NaN" ):number{
        const parentSelection = d3Select.select(parentElement)
        const eles = parentSelection.selectAll(tagName) as any
        const reasonLayer = d3Select.select(this.reasonSvg).select('.undesiredValue')

        let count_invalid_text = 0

        for (let i = 0; i < eles._groups[0].length; i++) {
            const content = eles._groups[0][i].innerHTML
            if (content.includes(keyword)) {
                count_invalid_text += 1
                if (this.needReason) {
                    // need test
                    if (tagName === "title"){
                        const parentBBox = eles._groups[0][i].parentElement.getBoundingClientRect()
                        this.highlight_error_with_rect(parentBBox, reasonLayer, this.config.invalidValue.highlightColor, 0.2)
                    }
                    else{
                        this.highlight_error_with_rect(eles._groups[0][i].getBoundingClientRect(), reasonLayer, this.config.invalidValue.highlightColor, 0.2)
                    }
                    
                }
            }

        }
        return count_invalid_text
    }

    /**
     * Invalid value in element text or attribute
     * @param tagName tag name of elements for detecting undesired words
     * @param keyword the undesired words
     * @param parentElement the parent element where the undesired words are
     */
     private check_single_undesired_property(parentElement:SVGElement, keyword = "NaN" ):number{
        const parentSelection = d3Select.select(parentElement)
        const eles = parentSelection.selectAll("*") as any
        const reasonLayer = d3Select.select(this.reasonSvg).select('invalidValue')
    
        let count_invalid_attr = 0

        for (let i = 0; i < eles._groups[0].length; i++) {
            const attrLis = eles._groups[0][i].attributes
            // console.log(attrLis[])
            if (typeof (attrLis) !== 'undefined') {
                for (let j = 0; j < attrLis.length; ++j) {
                    if (typeof attrLis[j].value == "string"){
                        if (attrLis[j].value.includes(keyword)) {
                            count_invalid_attr += 1
                            if (this.needReason) {
                                this.highlight_error_with_rect(eles._groups[0][i].getBoundingClientRect(), reasonLayer, this.config.invalidValue.highlightColor, 0.2)
                            }
                        }   
                    }
                }
            }
        }
        return count_invalid_attr
    }

    /**
     * Invalid value in element text or attribute
     * @returns 
     * @author Zixin
     */
    protected check_invalid_value(): boolean {

        let count_invalid_text = 0

        const detect_eles = ["text", "title", "tspan"]
        
        for (let i = 0; i < this.config.invalidValue.details.length; i++){
            for (let j = 0; j < detect_eles.length; j++){
                count_invalid_text += this.check_single_undesired_value(this.node ,detect_eles[j], this.config.invalidValue.details[i])
            }
        }

        let count_invalid_attr = 0

        // invalid value in attribute

        for (let i = 0; i < this.config.invalidValue.details.length; i++){
            
            count_invalid_attr += this.check_single_undesired_property(this.node, this.config.invalidValue.details[i])
        }
        
       
        return count_invalid_text > 0 || count_invalid_attr > 0
    }
}


/**
 * Obtain elements with a particular tag in a recursive manner
 * @param root the root DOM element
 * @param tagName obtain elements with the tagname
 * @returns 
 */
 const traverseDOMTree2ObtainAllVisibleElement = function (root: Element,  flag = false): Element[]{
    
    const result = checkVisible(root)
    
    const isClipPathRef = root.tagName == 'defs'
    const children = root.children
    
    if ((!result) && root.tagName != 'defs') {
        return []
    }
    else {
        const childrenElement = Array.from(children).map((e: Element) => {
            return traverseDOMTree2ObtainAllVisibleElement(e, isClipPathRef || flag)  // make sure clipPath is maintained
        }).reduce((a, b) => a.concat(b), [])

        return [root, ...childrenElement]
    }
}


/**
 * Obtain elements with a particular tag in a recursive manner
 * @param root the root DOM element
 * @param tagName obtain elements with the tagname
 * @returns 
 */
const traverseDOMTree2Obtain = function (root: Element, tagName: string, flag = false): boolean {
    let result = root.tagName == tagName
    const isClipPathRef = root.tagName == 'defs'
    const children = root.children
    Array.from(children).forEach((e: Element) => {
        const childResult = traverseDOMTree2Obtain(e, tagName, isClipPathRef || flag)  // make sure clipPath is maintained
        result = result || childResult  // make sure any paths containing the text element is maintained
    })
    if ((!result) && root.tagName != 'defs') {
        root.remove()
    }
    return result
}

/**
 * Obtain elements with a particular tag in a recursive manner
 * @param root the root DOM element
 * @param tagName obtain elements with the tagname
 * @returns 
 */
 const traverseDOMTree2RemoveInvisible = function (root: Element, flag = false){
    const result = checkVisible(root)
    const isClipPathRef = root.tagName == 'defs'

    if ((!result) && (!isClipPathRef)) {
        // console.log(root)
        root.remove()
    }
    else{
        const children = root.children
        Array.from(children).forEach((e: Element) => {
            traverseDOMTree2RemoveInvisible(e, isClipPathRef || flag)  // make sure clipPath is maintained
        })
    }

}

/**
 * Pause the program for some time
 * @param ms milleseconds
 */
const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const loadImage = function (url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => {
            resolve(image)
        })
        image.addEventListener('error', (err) => {
            reject(err)
        })
        image.src = url
        return image
    })
}

/**
 * Get the starting index of a pixel in a canvas data array
 * @param x x-coord
 * @param y y-coord
 * @param w the width of canvas
 */
function get_pixel_index(x: number, y: number, w: number) {
    return y * (Math.round(w) * 4) + x * 4
}



/**
 * Check if the element is visible by: 1.visibility 2.display 3.opacity
 * @param element html element
 * @returns if the element is visible
 */
function checkVisible(element: Element){
    // .replace("px", "")
    if (window.getComputedStyle(element, null).getPropertyValue('visibility') === "hidden"){
        return false
    }
    else if (window.getComputedStyle(element, null).getPropertyValue("display") === "none"){
        return false
    }
    else if (parseFloat(window.getComputedStyle(element, null).getPropertyValue('fill-opacity')) === 0){
        return false
    }
    else if (parseFloat(window.getComputedStyle(element, null).getPropertyValue('opacity')) === 0){
        return false
    }
    return true
}

function cloneAttributes(target: Element, source: Element) {
    [...source.attributes].forEach( attr => { 
        if(attr.name == 'class') return
        target.setAttribute(attr.nodeName ,attr.nodeValue!) 
    })
  }
  

export {
    ChartDetector,
    SVGChecklist,
}