import * as d3Scale from 'd3-scale'
import * as d3Color from 'd3-scale-chromatic'
import { AggregatedSVGChecklist, ErrorTestResultType, ReportElementType, ReportType } from './vis-testing/exec'
import { ErrorDetectConfig, ErrorType, errorTypes, mapOfErrorAbbr } from './vis-testing/config'
import { sequenceExpression } from '@babel/types'
interface TableDataType {
    key: string,
    deviceStr: string,
    datasetStr: string,
    interactStr: string,
    times: number,
    thumbnail: string,
    hasError: boolean,
    details: AggregatedSVGChecklist | ErrorTestResultType
    datasetIndex: number
    interactionIndex: number
    deviceIndex: number
}


class UITableDatum implements TableDataType {
    key: string
    deviceStr: string
    datasetStr: string
    interactStr: string
    times: number
    details: AggregatedSVGChecklist | ErrorTestResultType
    thumbnail!: string
    hasError: boolean
    datasetIndex: number
    interactionIndex: number
    deviceIndex: number

    width: number
    height: number
    deviceLabel: string
    datasetType: string
    datasetSeed: string
    datasetDim: string
    datasetVal: number | string
    interactSeq: string
    constructor(key: string, res: ReportElementType, report: ReportType) {
        Object.assign(this, res)
        const param = report.dataConfigArray[res.datasetIndex].value
        let paramStr = ''
        if(param != Number.NEGATIVE_INFINITY) paramStr = ': ' + param
        this.key = key
        const width = report.deviceArray[res.deviceIndex].width
        const height = report.deviceArray[res.deviceIndex].height
        this.deviceStr = `${width}×${height}`
        this.datasetStr = `${report.dataConfigArray[res.datasetIndex].tag}${paramStr}`
        this.datasetIndex = res.datasetIndex
        this.deviceIndex = res.deviceIndex
        this.interactionIndex = res.interactionIndex
        this.times = res.interactionIndex === -1? 1 : report.interactionArray[res.interactionIndex].times
        this.interactStr = res.interactionIndex === -1? 'static' : report.interactionArray[res.interactionIndex].tag
        this.details = res.details
        this.hasError = res.hasError


        this.width = width
        this.height = height
        this.deviceLabel = report.deviceArray[res.deviceIndex].label
        this.datasetType = report.dataConfigArray[res.datasetIndex].tag
        this.datasetSeed = report.dataConfigArray[res.datasetIndex].seed
        this.datasetDim = report.dataConfigArray[res.datasetIndex].dimension
        this.datasetVal = report.dataConfigArray[res.datasetIndex].value
        this.interactSeq = res.interactionIndex === -1? '' : report.interactionArray[res.interactionIndex].sequence.map(v => {
            v.element + ' ' + v.event
        }).reduce((a,b)=>a+', ' + b, '')
    }

    static getPrintTable(table: UITableDatum[]) {
        const keys = ['deviceIndex', 'datasetIndex', 'interactionIndex', 'deviceLabel', 'width',
    'height', 'datasetSeed', 'datasetType', 'datasetDim', 'datasetVal', 'interactSeq', 'interactStr','times', 'hasError']
        const errs = errorTypes
        console.log(table, 'fuck')
        return table.map((v) => {
            const result = {} as Record<any, any>
            result['runtime'] = v.hasError? v.times : 0
            keys.forEach((k) => {
                const key = k as keyof UITableDatum
                result[k] = v[key]
                if(v.hasError) {
                    errs.forEach((err)=> {
                        result[err] = 0
                    })
                }
                else {
                    errs.forEach((err) => {
                        const errKey = err as keyof AggregatedSVGChecklist
                        const details = v.details as AggregatedSVGChecklist
                        result[err] = details[errKey]
                    })
                }
            })
            return result
        })

    }
    
}
  
type PaginationType = {
    current: number
    pageSize: number
}

type FilterType = {
    name: string
    address: string
}
type ColumnType = {
    title?: string
    dataIndex: string
    filters?: {
        text: string
        value: string
        children?: {
        text: string
        value: string
        }[]
    }[]
    onFilter?: (value: string, record: TableDataType) => boolean
    sorter?: (a: TableDataType, b: TableDataType) => number
    sortDirections?: string[]
    defaultSortOrder?: string
    filterMultiple?: string[] | boolean,
    fixed?: string
}
  
const mapOfErrorIcon = new Map(
    [['text_overlap', './error-icons/textOverlap.svg'],
    ['text_mark_overlap', './error-icons/crossOverlap.svg'],
    ['mark_overlap', './error-icons/markOverlap.svg'],
    ['contrast', './error-icons/contrast.svg'],
    ['overflow', './error-icons/overflow.svg'],
    ['small_mark', './error-icons/smallMark.svg'],
    ['small_text', './error-icons/smallText.svg'],
    ['undesired_value', './error-icons/undesiredValue.svg'],
    ['error', './error-icons/error.svg']]) as Map<keyof AggregatedSVGChecklist, string>

const getColumnForValue = function (alias: string) {
    const key = alias as keyof AggregatedSVGChecklist
    return {
        dataIndex: 'details',
        width: '45px',
        sorter: (a: TableDataType, b: TableDataType) => {
            if((!a.hasError) && (!b.hasError)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return a.details[key] - b.details[key]
            }  
            if (b.hasError) return 1
            if (a.hasError) return -1
            return 0
        },
        sortDirections: ['descend', 'ascend'],
        filterMultiple: true,
        align: 'center',
        slots: { 
          title: `customTitle_${key}`,
          customRender: `customValue_${key}`
        },
    }
}

const getAllUIColumns = function (detectConfig: ErrorDetectConfig): ColumnType[] {
    const previewColumn = {
        title: '',
        dataIndex: 'thumbnail',
        slots: {
            customRender: `customValue_preview`
        },
        align: 'center',
        width: '30px'
    }
    const sumColumn = {
        title: 'Total',
        dataIndex: 'times',
        align: 'center',
        sorter: (a: TableDataType, b: TableDataType) => a.times - b.times,
        sortDirections: ['descend', 'ascend']

    }
    const deviceConfigColumn = {
        title: 'W×H',
        dataIndex: 'deviceStr',
        sorter: (a: TableDataType, b: TableDataType) =>  a.deviceIndex - b.deviceIndex,
        sortDirections: ['descend', 'ascend'],
        fixed: 'left'
    }
    const datasetConfigColumn = {
        title: 'Data',
        dataIndex: 'datasetStr',
        sorter: (a: TableDataType, b: TableDataType) =>  a.datasetIndex - b.datasetIndex,
        sortDirections: ['descend', 'ascend'],
        fixed: 'left'
    }
    const interactionConfigColumn = {
        title: 'Interact',
        sorter: (a: TableDataType, b: TableDataType) =>  a.interactionIndex - b.interactionIndex,
        dataIndex: 'interactStr',
        fixed: 'left'
    }
    // const interactionTimesColumn = {
    //     title: 'Times',
    //     dataIndex: 'times',
    //     sorter: (a: TableDataType, b: TableDataType) => a.times - b.times
    // }
    const runtimeErrorColumn = {
        dataIndex: 'details',
        width: '40px',
        slots: {
            title: `customTitle_runtime`,
            customRender: `customValue_runtime`
        },
        align: 'center',
        sorter: (a: TableDataType, b: TableDataType) => {
            if (a.hasError && b.hasError) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return a.details.error - b.details.error
            }
            if (a.hasError) return 1
            if (b.hasError) return -1
            return 0
        }

    }
    const errorColumns = errorTypes
      .filter((v: ErrorType) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return detectConfig[mapOfErrorAbbr.get(v)].isOn
      })
      .map(v => {
        return getColumnForValue(v)
    })
    return [
        previewColumn,
        deviceConfigColumn,
        datasetConfigColumn,
        interactionConfigColumn,
        sumColumn,
        // interactionTimesColumn,
        runtimeErrorColumn,
        ...errorColumns
    ]
}

const datasetPreviewColumns =  [{
    title: 'field',
    dataIndex: 'field'
  }, {
    title: 'min',
    dataIndex: 'min'
  }, {
    title: 'max',
    dataIndex: 'max'
  }, {
    title: 'type',
    dataIndex: 'type'
  },{
    title: 'dist',
    slots: { customRender: 'previewDistributionColumn'}

  }]

const ratioColorScale = d3Scale.scaleSequential(
    [0,1],
    (t: number) => d3Color.interpolateReds((t+0.3)/2)
    )

const getStat = function (table:TableDataType[], config: ErrorDetectConfig):any {
    const cntTimes = table.map((v) => v.times).reduce((a, b)=>a + b, 0)
    const cntRuntimes = table.map((v) => {
        if (!v.hasError) return 0
        else {
            const err = (v.details as ErrorTestResultType)
            return typeof(err.error)==='number'? (err.error as number): 1
        }
    }).reduce((a, b) => a + b, 0)
    const errorDetectSheet = [] as any[]
    if (config.smallText.isOn)
        errorDetectSheet.push(getElementStat(table, 'small_text'))
    if (config.smallMark.isOn)
        errorDetectSheet.push(getElementStat(table, 'small_mark'))
    if (config.crossOverlap.isOn)
        errorDetectSheet.push(getElementStat(table, 'text_mark_overlap'))
    if (config.textOverlap.isOn)
        errorDetectSheet.push(getElementStat(table, 'text_overlap'))
    if (config.markOverlap.isOn)
        errorDetectSheet.push(getElementStat(table, 'mark_overlap'))
    if (config.contrast.isOn)
        errorDetectSheet.push(getElementStat(table, 'contrast'))
    if (config.overflow.isOn)
        errorDetectSheet.push(getElementStat(table, 'overflow'))
    if (config.invalidValue.isOn)
        errorDetectSheet.push(getElementStat(table, 'undesired_value'))
    errorDetectSheet.sort((a, b) => b.value - a.value)
    const result = {
        times: cntTimes,
        runtimeErrors: cntRuntimes,
        displayList: errorDetectSheet
    }
      return result
}

const getElementStat = function (records: TableDataType[], field: ErrorType) {
    const array = records.filter(v => !v.hasError).map(v => {
        const val = v.details as AggregatedSVGChecklist
        return val[field] * val.valid_total
    })
    const sum = array.reduce((a, b) => a + b, 0)
    return {
        error: field,
        value: sum
    }
}

const generateText = function (sheet: any, config: any) {
    return ""
    // console.log(sheet.displayList, config)
    // const lines = sheet.displayList.map((v: any) => {
    //     return `${v.error}: ${v.value}</br>`
    // }).reduce((a:string, b:string)=>a+b, '')
    // return `
    // There are ${sheet.times} tests.</br>
    // -------------------------------------------</br>
    // Runtime errors: ${sheet.runtimeErrors}</br>
    // ${lines}
    // `
}


export {
    TableDataType,
    UITableDatum,
    getAllUIColumns,
    generateText,
    mapOfErrorIcon,
    datasetPreviewColumns,
    ratioColorScale,
    errorTypes,
    getStat
}