<template>
   <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsedNavigation" collapsible theme="light">
      <div class="logo" />
      <!-- <a-menu v-model:selectedKeys="selectedKeysForNavigation" theme="dark" mode="inline">
        <a-menu-item key="1">
           <router-link to="/testpage">
              <SettingOutlined />
              <span>Test</span>
              </router-link>
        </a-menu-item>
        <a-menu-item key="3">
          <router-link to="/browser">
              <PieChartOutlined />
              <span>Browser</span>
              </router-link>
        </a-menu-item>
    
        <a-menu-item key="6">
          <router-link to="/about">
         <question-outlined />
              <span>About</span>
              </router-link>
        </a-menu-item>
        <a-menu-item key="9">
          <file-outlined />
          <span>File</span>
        </a-menu-item>
      </a-menu> -->
      <a-menu v-model:selectedKeys="selectedKeysForNavigation" theme="light" mode="inline">
        <a-menu-item key="1">
          <router-link to="/">
          <home-outlined />&nbsp;
          <span>Home</span>
          </router-link>
        </a-menu-item>
         <a-menu-item key="2">
          <router-link to="/tool">
          <CodeOutlined />&nbsp;
          <span>Tool</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="3">
          <router-link to="/about">
         <question-outlined />&nbsp;
              <span>About</span>
              </router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-content style="margin: 0 16px">
        <div class="intro-div">
          <div class="text-div">
        <h1> Welcome to Vizarre!</h1>
        <p>Vizarre is a web-based testing tool for d3-based visualization programs. To play with Vizarre, please click the 2nd button in the navigation.</p>
        <div class="video-div">
            <!-- <video controls>
              <source :src="`./vizarre-demo.mp4`" type="video/mp4"/>
            </video> -->
            <iframe width="560" height="315" src="https://www.youtube.com/embed/MFBJFiTKpVA" title="Demo of Vizarre" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </div>
        </div>
          </a-layout-content>
      <a-layout-footer style="text-align: center">
        Vizarre ©2024
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import {
  QuestionOutlined,
  HomeOutlined,
  CodeOutlined
} from '@ant-design/icons-vue'
export default defineComponent({
  name: 'Home',
  components: {
    QuestionOutlined,
    HomeOutlined,
    CodeOutlined
  },
  setup() {
    const selectedKeysForNavigation = reactive(['1'])
    const collapsedNavigation = ref(true)
    return {
      selectedKeysForNavigation,
      collapsedNavigation
    }
  }
});
</script>
<style lang="scss">
.logo {
  height: 32px;
  margin: 16px;
  background-color: #EFF2F5;
}
.ant-layout-content {
  margin: 0 0 0 16px !important;
}

.site-layout .site-layout-background {
  background: #fff;
}
.site-layout .site-layout-background {
  background: #141414;
}
.ant-layout-footer {
  padding: 5px 5px !important;
}
.splitpanes__pane {
  display: flex;
  justify-content: center;
  align-items: center;

}
.splitpanes__splitter {
  background-color: #e9e9e9 !important;
}

.splitpanes--vertical>.splitpanes__splitter {
  min-width: .3vw !important;
}
.splitpanes--horizontal>.splitpanes__splitter {
  min-height: .3vw !important;
}
.splitpanes__pane {
  .container {
    width: 100%;
    height: 100%;
  }
  overflow-y: scroll !important;
  overflow-x: hidden;
}
.intro-div{
  margin-top: 2vh;
  h1 {
    font-size: 2em;
    font-weight: 800;
  }
  text-align: center;
    .text-div{
      text-align: left;
      max-width: 65vw;
      margin: auto;
    }

  .video-div{
    text-align: center;
    margin: auto;
    padding-bottom: 56.25%;
    position: relative;
    iframe {
        width: 100%;
        height: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
      }
  }
  
}

</style>
