/**
 * This is the doc comment for config.ts.
 * @module vis-testing
 * Configuration of test cases.
 */

import * as d3 from 'd3'
import { randintGenerator, getRandomAlphabet, shuffleWithSeed } from './utils/wheel'
import { SVGChecklist } from './detect'
import { EventBinding } from './analyze'
import randomSeed from 'random-seed'
import { mapState } from 'vuex'


const commonDevices = [
    [360, 640],
    [500, 500],
    [896, 414],
    [640, 360],
    [414, 896]
]
/**
 * Device configuration
 */
class DeviceSettingType {
    label: string
    width: number
    height: number
    key?: string
    static defaultWidth = 500
    static defaultHeight = 500
    constructor(key: string, label?: string, width?: number, height?: number) {
        this.key = key
        this.label = label || key
        this.width = width || DeviceSettingType.defaultWidth
        this.height = height || DeviceSettingType.defaultHeight
    }
}


type BasicConfigTagType = 'extreme' | 'scale' | 'perturb' | 'shuffle' | 'sample' | 'generation' | 'static'
interface BasicConfigType {
    dimension: string
    value: number | string
    tag: BasicConfigTagType
    seed: string
}
class BasicDataConfig implements BasicConfigType {
    tag: BasicConfigTagType
    value: number | string
    dimension: string
    seed: string
    constructor(tag: BasicConfigTagType, value: number | string = Number.NEGATIVE_INFINITY, dimension = '') {
        this.tag = tag
        this.value = value
        this.dimension = dimension
        this.seed = `seed_${Math.random() * 200}`
    }
}
/**
 * Configuration for generating test data.
 */
class DataConfig {
    extreme: Array<BasicConfigType> = []
    scale: Array<BasicConfigType> = []
    perturb: Array<BasicConfigType> = []
    shuffle = 0
    sample: Array<number> = []
    generation = 0
    static = true
    get isOn() {
        return {
            extreme: this.extreme.length > 0,
            scale: this.scale.length > 0,
            perturb: this.perturb.length > 0,
            shuffle: this.shuffle > 0,
            sample: this.sample.length > 0,
            generation: this.generation > 0,
            static: this.static == true
            
        }
    }
    get dataConfigList(): Array<BasicConfigType> {
        const datalist = [] as Array<BasicDataConfig>
        const todos = Object.keys(this.isOn).filter((v: string) => {
            const key = v as BasicConfigTagType
            return this.isOn[key]
        }) as unknown as Array<BasicConfigTagType>
        todos.forEach(tag => {
            if (tag == 'shuffle' || tag == 'generation') {
                datalist.push(...Array(this[tag]).fill(new BasicDataConfig(tag)))
            }
            else if (tag == 'sample') {
                datalist.push(...this[tag].map(v => new BasicDataConfig(tag, v)))
            }
            else if (tag == 'static') {
                datalist.push(new BasicDataConfig(tag))
            }
            else {
                datalist.push(...this[tag].map((v, idx) => new BasicDataConfig(tag, v.value, v.dimension)))
            }
        })
        return datalist
    }
    constructor(config: Partial<DataConfig>) {
        Object.assign(this, config)
    }
    getDefaultDataConfigFromData(dataset: unknown[]) {
        throw new Error('Method not implemented.');
    }
    getDataConfigFromUI() {
        throw new Error('Method not implemented.');
    }
}

type InteractionAtomType = {
    element?: string,
    event: string
}
interface InteractionConfigType {
    tag: string,
    seed?: number,
    times?: number,
    keep?: boolean,
    sequence: Array<InteractionAtomType>
}
class InteractionConfig implements InteractionConfigType {
    static defultTimes = 10
    tag: string
    seed: number
    times: number
    keep: boolean
    sequence: Array<InteractionAtomType> = []
    static nullConfig = new InteractionConfig({
        sequence: [],
        tag: 'static',
        times: 1
    })
    constructor(config: Partial<InteractionConfigType>) {
        this.sequence = config.sequence || []
        this.tag = config.tag || 'I' + getRandomAlphabet(3)
        this.seed = config.seed || randintGenerator(1, 100)()
        this.times = config.times || InteractionConfig.defultTimes
        this.keep = config.keep == false ? false : true
    }
    static getDefaultSequence(bindings: EventBinding[]) {
        // parse event bindings and derive a set of configs
        const sequences = [] as InteractionConfig[]
        return sequences
    }

}


type ErrorDetectConfigAtomType = {
    isOn: boolean,
    threshold?: number,
    details?: any,
    highlightColor:string
}
interface ErrorDetectConfigType {
    smallText: ErrorDetectConfigAtomType
    smallMark: ErrorDetectConfigAtomType
    textOverlap: ErrorDetectConfigAtomType
    overflow: ErrorDetectConfigAtomType
    markOverlap: ErrorDetectConfigAtomType
    crossOverlap: ErrorDetectConfigAtomType
    contrast: ErrorDetectConfigAtomType
    invalidValue: ErrorDetectConfigAtomType
    error: ErrorDetectConfigAtomType,
    timeout: ErrorDetectConfigAtomType
}

type DetectTypeTableElement = {
    key: string,
    ref: keyof SVGChecklist | 'error' | 'timeout',
    name: string,
    isOn: boolean,
    highlightColor?: string,
    threshold?: number,
    details?: any,
    hint?: string,
    _key: keyof ErrorDetectConfigType,
}

const errorTypes = [
    'undesired_value',
    'small_text',
    'small_mark',
    'overflow',
    'text_overlap',
    'text_mark_overlap',
    'mark_overlap', 
    'contrast',
] as Array<ErrorType>

type ErrorType = 'undesired_value' | 'small_text' | 'small_mark'| 'overflow'| 'text_overlap' | 'text_mark_overlap' | 'mark_overlap' | 'contrast'

/**
 * Configuration for detecting errors based on heuristics.
 */
class ErrorDetectConfig implements ErrorDetectConfigType {
    timeout = {threshold: 10000, isOn: true, highlightColor: 'white'}
    smallText = { threshold: 14, isOn: true, highlightColor: "#e41a1c" }
    smallMark = { threshold: 10, isOn: true, highlightColor: "#377eb8" }
    textOverlap = { threshold: 0.05, isOn: true, highlightColor: "#4daf4a" }
    overflow = { threshold: 0.05, details: 'svg', isOn: true, highlightColor: "#984ea3" }
    markOverlap = { threshold: 0.05, isOn: false, highlightColor: "#ff7f00" }
    crossOverlap = { threshold: 0.05, isOn: true, highlightColor: "#ffff33"  }
    contrast = { threshold: 1/4.5, isOn: true, highlightColor: "#a65628" }
    invalidValue = { details: ['NaN', 'Null', '[Object, Object]'], isOn: true, highlightColor: "#f781bf" }
    error = { isOn: true, highlightColor: "black" }
    constructor(config: Partial<ErrorDetectConfig>) {
        const configKeys = Object.keys(config) as Array<keyof Partial<ErrorDetectConfig>>
        configKeys.forEach(v => {
            Object.assign(this[v], config[v])
        })
    }

    update(field: keyof ErrorDetectConfigType, options: any) {
        Object.keys(options).forEach(v => {
            if (v == 'threshold' && field != 'invalidValue' && field != 'error')
                this[field].threshold = options[v]
            if (v == 'isOn')
                this[field].isOn = options[v]
        })
    }

    transformResult(res: SVGChecklist): Array<{error: string, value: boolean | number}> {
        const results = [] as Array<{error: string, value: boolean | number}>
        errorTypes.forEach((errorKey) => {
            const resKey = errorKey as any
            const detectKey = mapOfErrorAbbr.get(resKey)
            const field = this[detectKey!] as ErrorDetectConfigAtomType
            if (field.isOn) {
                results.push({
                    error: errorKey,
                    value: res[errorKey]? 1 : 0
                })
            }
        })
        results.sort((a, b) => b.value?1:-1)
        return results
    }

    /**
    * @returns the detect type and its specifications for UI
    */
    get uiTable():DetectTypeTableElement[] {
            return [{
                'key': '0',
                'ref': 'timeout',
                'name': 'time out',
                'isOn': true,
                'highlightColor': 'white',
                'threshold': 10000,
                'hint': 'ms',
                '_key': 'timeout'
            }, {
                'key': '1',
                'ref': 'error',
                'name': 'runtime error',
                'isOn': this.error.isOn,
                'highlightColor': 'white', 
                '_key': 'error'
            }, {
                'key': '2',
                'ref': 'undesired_value',
                'name': 'undesired value',
                'isOn': this.invalidValue.isOn,
                'details': this.invalidValue.details,
                'highlightColor': this.invalidValue.highlightColor, 
                '_key': 'invalidValue'
            }, {
                'key': '3',
                'ref': 'small_text',
                'name': 'small text',
                'isOn': true,
                'threshold': 16,
                'hint': 'px',
                'highlightColor': this.smallText.highlightColor, 
                '_key': 'smallText'
            }, {
                'key': '4',
                'ref': 'small_mark',
                'name': 'small mark',
                'isOn': true,
                'threshold': this.smallMark.threshold,
                'highlightColor': this.smallMark.highlightColor, 
                'hint': 'px',
                '_key': 'smallText'
            }, {
                'key': '5',
                'ref': 'overflow',
                'name': 'overflow',
                'isOn': this.overflow.isOn,
                'details': this.overflow.details,
                'threshold': this.overflow.threshold,
                'highlightColor': this.overflow.highlightColor, 
                'hint': '(%)',
                '_key': 'overflow'
            }, {
                'key': '6',
                'ref': 'text_mark_overlap',
                'threshold': this.crossOverlap.threshold,
                'name': 'overlapping text and mark',
                'isOn': this.crossOverlap.isOn,
                'highlightColor': this.crossOverlap.highlightColor, 
                '_key': 'crossOverlap',
                'hint': '(%)'
            }, {
                'key': '7',
                'ref': 'contrast',
                'name': 'contrast',
                'isOn': this.contrast.isOn,
                'threshold': this.contrast.threshold,
                'highlightColor': this.contrast.highlightColor, 
                'hint': '(ratio)',
                '_key': 'contrast'
            }, {
                'key': '8',
                'ref': 'text_overlap',
                'threshold': this.textOverlap.threshold,
                'highlightColor': this.textOverlap.highlightColor, 
                'name': 'text overlap',
                'isOn': this.textOverlap.isOn,
                'hint': '(%)',
                '_key': 'textOverlap'
            }, {
                'key': '9',
                'ref': 'mark_overlap',
                'name': 'mark overlap',
                'isOn': this.markOverlap.isOn,
                'highlightColor': this.markOverlap.highlightColor, 
                'threshold': this.markOverlap.threshold,
                'hint': '(%)',
                '_key': 'markOverlap'
            }
        ]
    }
}


const mapOfErrorAbbr = new Map(
    [['text_overlap', 'textOverlap'],
    ['text_mark_overlap', 'crossOverlap'],
    ['mark_overlap', 'markOverlap'],
    ['contrast', 'contrast'],
    ['overflow', 'overflow'],
    ['small_mark', 'smallMark'],
    ['small_text', 'smallText'],
    ['undesired_value', 'invalidValue'],
    ['error', 'error']]) as Map<ErrorType|'error', keyof ErrorDetectConfig>


const tmpInteractionConfig = [{
    tag: 'mouseenter',
    seed: 0,
    times: 2,
    keep: false,
    sequence: [{
        event: 'mouseenter'
    }]
}]

/**
 * General configuration for testing a visualization
 */
class TestConfigFile {
    dependencies: Record<string, string> = { 'd3': '7.0.0' }
    deviceConfig: Array<DeviceSettingType> = [new DeviceSettingType('0')]
    dataConfig: DataConfig = new DataConfig({})
    errorDetection: ErrorDetectConfig = new ErrorDetectConfig({})
    interactionConfig: Array<InteractionConfig>
    constructor(config: Partial<TestConfigFile>) {
        if (config.deviceConfig) {
            this.deviceConfig = config.deviceConfig.map((v, i) => {
                const key = v.key || `${i}`
                const label = v.label || `${i}`
                const width = v.width
                const height = v.height
                return new DeviceSettingType(key, label, width, height)
            })
        }
     
        if(config.dependencies) this.dependencies = config.dependencies
        if (config.dataConfig) this.dataConfig = new DataConfig(config.dataConfig)
        this.deviceConfig.forEach((v, idx) => v.key = idx.toString())
        if(config.interactionConfig)
            this.interactionConfig = config.interactionConfig.map(v => new InteractionConfig(v))
        else
            this.interactionConfig = [] //tmpInteractionConfig.map(v => new InteractionConfig(v))
        if(config.errorDetection) this.errorDetection = new ErrorDetectConfig(config.errorDetection)
    }
}

const BINSIZE = 10

/**
 * Generate a comprehensive list for each dimensions of the input dataset
 * @param dataset the user-uploaded dataset
 * @returns attribute table to be used in the UI
 */
const getDataAttrTable = function (dataset: any[]):any {
    if (dataset.length == 0) return []
    const attributeList = Object.keys(dataset[0])
    const attrTable = []
    let key = 0
    for (const attr of attributeList) {
        const field = attr
        const ex = (dataset[0] as any)[attr]
        const type = typeof (ex)
        const slice = type == 'number' ?
            dataset.map(v => (v as any)[attr]) : dataset.map(v => (v as any)[attr].length)
        const min = Math.min(...slice)
        const max = Math.max(...slice)
        const bins = d3.bin().thresholds(BINSIZE)(slice).map(v => v.length)
        const insertValues = [] as Array<number | string>
        const result = {
            field,
            min,
            max,
            type,
            key,
            bins,
            insertValues
        }
        key += 1
        attrTable.push(result)
    }
    return attrTable
}


const insertExtremeValue = function (originalDataset: Record<string, unknown>[], attr: string, value: number | string, seed:string) {
    const dataset = [...originalDataset]
    const gen = randomSeed.create(seed)
    const idx = gen.intBetween(0, dataset.length - 1)
    const example = dataset[idx]
    const newVal = Object.assign({}, example) as typeof example
    newVal[attr] = value
    dataset.push(newVal)
    return dataset
}

/**
 * Rescale the data size of the datase
 * @param originalDataset 
 * @param k the scale
 */
const sampleDataset = function (originalDataset: Record<string, unknown>[], k = 1, seed: string) {
    const gen = randomSeed.create(seed)
    const dataset = [] as typeof originalDataset
    const datasetLength = Math.round(originalDataset.length * k)
    const randint = gen.intBetween(0, originalDataset.length - 1)
    if (datasetLength < 1) return [originalDataset[randint]]
    const fold = Math.floor(k)
    const randIndexes = [] as Array<number>
    for (let i = 0; i < fold * originalDataset.length; i++) {
        randIndexes.push(gen.intBetween(0, originalDataset.length - 1))
    }
    const remains = datasetLength - fold * originalDataset.length
    for (let i = 0; i < remains; i ++) {
       randIndexes.push(gen.intBetween(0, originalDataset.length - 1))
    }
    randIndexes.sort((a, b) => a - b)
    for (let i = 0; i < randIndexes.length; i ++) {
        dataset.push(originalDataset[randIndexes[i]])
    }
    // console.log(dataset, randIndexes, originalDataset.length)
    return dataset
}

const scaleDataset = function (originalDataset: Record<string, unknown>[], attr: string, k=1) {
    const dataset = [...originalDataset]
    dataset.forEach(v => {
        const val = v[attr]
        if (typeof (val) == 'string') {
            v[attr] = getRandomAlphabet(Math.floor(val.length * k) + 1)
        }
        else if (typeof (val) == 'number') {
            v[attr] = val * k
        }
    })
    return dataset
}

const perturbDataset = function (originalDataset: Record<string, unknown>[], attr: string, k=0.01) {
    const dataset =  [...originalDataset] as unknown as Record<string, unknown>[]
    const type = typeof (dataset[0][attr])
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
    const slice = type == 'string' ? dataset.map(v => v[attr].toString().length) : dataset.map(v => v[attr]) as number[]
    const max = Math.max(...slice)
    const min = Math.min(...slice)
    const mid = (max + min) / 2
    dataset.forEach(v => {
        if (type == 'string') {
                 // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
            const len = Math.floor(Math.abs(v[attr].toString().length - mid) * k + mid)
            v[attr] = getRandomAlphabet(len)
        }
        if (type == 'number') {
            v[attr] = mid + (Number(v[attr]) - mid) * k
        }
    })
    return dataset
}

const shuffleDataset = function (originalDataset: Record<string, unknown>[], seed: string) {
    return shuffleWithSeed(originalDataset, seed)
}

const getNewDataset = function (origin: Record<string, unknown>[], dataConfigInstance: BasicConfigType) {
    const { tag, dimension, value } = dataConfigInstance
    let dataset = origin.map(v => Object.assign({}, v))
    const val = value as number
    switch (tag) {
        case 'scale':
            dataset = scaleDataset(dataset, dimension, val)
            break
        case 'perturb':
            dataset = perturbDataset(dataset, dimension, val)
            break
        case 'sample':
            dataset = sampleDataset(dataset, val, dataConfigInstance.seed)
            break
        case 'shuffle':
            dataset = shuffleDataset(dataset, dataConfigInstance.seed)
            break
        case 'extreme':
            dataset = insertExtremeValue(dataset, dimension, val, dataConfigInstance.seed)
            break
        case 'static':
            break
        case 'generation':
            break // !!! to implement
        default:
            break
    }
    return dataset
}

export {
    getDataAttrTable,
    getNewDataset,
    errorTypes,
    ErrorType,
    BasicConfigType,
    BasicDataConfig,
    ErrorDetectConfigAtomType,
    InteractionConfigType,
    InteractionConfig,
    DeviceSettingType,
    TestConfigFile,
    ErrorDetectConfig,
    ErrorDetectConfigType,
    InteractionAtomType,
    DataConfig,
    mapOfErrorAbbr,
    commonDevices
}

