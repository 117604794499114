/* eslint-disable */
/**
 * This is the doc comment for exec.ts
 * @module vis-testing
 * Run testing.
 */
import * as d3 from 'd3'

import * as polyvm from 'node-polyfill-webpack-plugin'
import * as vm from 'vm-browserify'
import { Analyzer, EventBinding } from './analyze'
import { BasicConfigType, InteractionConfig, InteractionAtomType, DeviceSettingType, TestConfigFile, getNewDataset, errorTypes} from './config'
import { SVGChecklist, ChartDetector } from './detect'
import { getDependencySheet } from './utils/packageManager'
import { getRandomAlphabet, randintGenerator } from './utils/wheel'
import * as randomSeed from 'random-seed'
interface ErrorTestResultType {
    error: string
}
interface AggregatedSVGChecklist {
    text_overlap: number,
    mark_overlap: number,
    text_mark_overlap: number,
    contrast: number,
    small_text: number,
    small_mark: number,
    undesired_value: number,
    overflow: number,
    valid_total: number
    timeout: number
}

class ErrorCounter implements AggregatedSVGChecklist {
    timeout = 0
    text_overlap = 0
    mark_overlap = 0
    text_mark_overlap = 0
    contrast = 0
    small_text = 0
    small_mark = 0
    undesired_value = 0
    overflow = 0
    thumbnails: string[] = []
    total = 0
    errorCount = 0
    errorList = [] as Array<{index: number, error: string}>
    get valid_total () {
        return this.total - this.errorCount
    }
    update(res: SVGChecklist) {
        this.total += 1
        errorTypes.forEach((key) => {
            if(res[key]) this[key] += 1
        })
    }
    updateError(idx: number, msg:string) {
        this.errorCount += 1
        this.errorList.push({
            index: idx,
            error: msg
        })
    }
    get stat(): AggregatedSVGChecklist {
        if (this.total == 0) console.warn('Getting stat of interaction simulation: Divide by Zero!')
        const statRes = {} as Record<string, number>
        errorTypes.forEach((key: string) => {
            const k = key as keyof AggregatedSVGChecklist
            statRes[k] = this.total != 0? this[k] / this.total : NaN
        })
        statRes.error = this.errorCount
        statRes.valid_total = this.valid_total
        return statRes as unknown as AggregatedSVGChecklist
    }
    get count(): AggregatedSVGChecklist {
        const statRes = {} as Record<string, number>
        errorTypes.forEach((key: string) => {
            const k = key as keyof AggregatedSVGChecklist
            statRes[k] = this[k]
        })
        statRes.error = this.errorCount
        statRes.valid_total = this.valid_total
        return statRes as unknown as AggregatedSVGChecklist
    }
    static convertBooleanToNumber(res: SVGChecklist): AggregatedSVGChecklist  {
        const statRes = {} as Record<string, number|string>
        errorTypes.forEach((key: string) => {
            const k = key as keyof AggregatedSVGChecklist
            const p = key as keyof SVGChecklist
            statRes[k] = res[p] ? 1 : 0
        })
        if (res.thumbnail) statRes['thumbnail'] = res.thumbnail
        statRes.valid_total = 1
        return statRes as unknown as AggregatedSVGChecklist
    }

}

interface ReportElementType {
    datasetIndex: number
    deviceIndex: number
    interactionIndex: number
    details: AggregatedSVGChecklist | ErrorTestResultType
    hasError: boolean
    thumbnail?: string

}

interface ReportType {
    datasetArray: Array<any>
    deviceArray: Array<DeviceSettingType>
    dataConfigArray: Array<BasicConfigType>
    interactionArray: Array<InteractionConfig>
    results: Array<ReportElementType>
}

/**
 * Wrapper of the execution function
 */
class Runner {
    private readonly styleEleId = `styleSheet_${getRandomAlphabet(5)}`
    private isDestroyed = false
    public code: string
    public config: TestConfigFile
    public dataConfigList: Array<BasicConfigType>
    public style: string
    public dataset: Array<Record<string, unknown>>
    public bindings: EventBinding[] = []
    private dependencySheet: Record<string, any>
    constructor(code: string, originalDataset: Record<string, unknown>[], config: TestConfigFile, styleSheet = ``, bindings: EventBinding[]=[]) {
        d3.selectAll('.custom_style').remove()
        const style = document.createElement('style') as HTMLStyleElement
        style.id = this.styleEleId
        style.classList.add('custom_style')
        document.head.appendChild(style)
        style.innerText = styleSheet
        this.config = config
        this.dependencySheet = getDependencySheet(this.config.dependencies)
        this.dataset = originalDataset
        this.dataConfigList = config.dataConfig.dataConfigList
        this.style = styleSheet
        this.code = code
        this.bindings = bindings
    }
    // public updateBinding(): Runner {
    //     if(this.hasBinding) return this
    //     this.bindings = this.analyzer.getAllEventBindings()
    //     this.code = this.analyzer.code
    //     this.hasBinding = true
    //     return this
    // }
    public updateMeta(key: 'code' | 'style' | 'dataset' | 'dataConfigList', val: unknown): void {
        this[key] = val as any
    }
    private static getTestCode(code: string, afore='', after=''): string {
        return `
            ${afore}
            ${code}
            try {
                svg.innerHTML = ''
                plot(data, svg, width, height)
                ${after}
            } catch(err) {
                error = err
            }
        `
    }

    public test(svg: SVGElement, dataset: unknown[], width: number, height: number): string {
        
        const sandbox = vm.createContext({
            ...this.dependencySheet,
            data: dataset,
            svg: svg,
            width: width,
            height: height,
            error: ''
        })

        // try {
        //    vm.runInNewContext('while (true);', {}, { timeout: 1 , microtaskMode: 'afterEvaluate' });
        //   } catch (err) {
        //     console.log(err, 'test error');
        //   }
        
         console.log(this.config.errorDetection.timeout.threshold, 'threshold', typeof(this.config.errorDetection.timeout.threshold))
        // const sandbox = {
        //     ...this.dependencySheet,
        //     data: dataset,
        //     svg: svg,
        //     width: width,
        //     height: height,
        //     error: ''
        //  }
        // const runner = new vm2.VM({
        //     sandbox: sandbox,
        //     timeout: 1,
        //     allowAsync: false
        // })
        // try {
        //     runner.run(
        //         Runner.getTestCode(this.code)
        //     )
        // } catch (e) {
        //     console.log(e, 'timeout')
        // }
        try {
            vm.runInNewContext(
                Runner.getTestCode(this.code),
                sandbox, 
                { 
                    timeout: 10, // this.config.errorDetection.timeout.threshold,
                    microtaskMode: 'afterEvaluate' 
                }
            )
        } catch (e) {
            console.log('timeout')
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sandbox.error = 'timeout'
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return sandbox.error as string
    }

    public testWithInteraction(svg: SVGElement, dataset: unknown[], deviceCase: DeviceSettingType, interactCase: InteractionConfig, seed:string): string {
        const sandbox = vm.createContext({
            ...this.dependencySheet,
            data: dataset,
            svg: svg,
            width: deviceCase.width,
            height: deviceCase.height,
            error: '',
            eventTriggerFunc: eventTriggerFunc,
            sequence: interactCase.sequence,
            errorMsg: '',
            bindings: this.bindings,
            keep: interactCase.keep,
            seed: seed
        })
        const timenow = Date.now()
        const code = Runner.getTestCode(this.code, '', 'eventTriggerFunc(svg, sequence, bindings, keep, errorMsg, seed)')
        try {
            
            vm.runInNewContext(code, sandbox, {
                timeout: this.config.errorDetection.timeout.threshold,
                microtaskMode: 'afterEvaluate' 
            })
        } catch(e) {
            console.log('timeout')
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sandbox.error = 'timeout'
        }
       
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return sandbox.error + sandbox.errorMsg as string
    }


    /**
     * Re-run the svg generation for one instance
     * @param svgEle 
     * @param deviceIdx 
     * @param dataIdx 
     */
    public async getInstance(svgEle: SVGElement, deviceIdx: number, dataIdx: number, interactionIdx: number) {
        d3.select(svgEle).html('')
        const device = this.config.deviceConfig[deviceIdx]
        const dataConfig = this.config.dataConfig.dataConfigList[dataIdx]
        const interactionConfig = this.config.interactionConfig[interactionIdx]
        const dataset = getNewDataset(this.dataset, dataConfig)
        this.test(svgEle, dataset, device.width, device.height)
        //this.testInteraction(svgEle, interactionConfig)
    }

   

    public async exec(divId = 'test-svg-div', store:any) {
        const detectConfig = this.config.errorDetection
        const deviceConfig = this.config.deviceConfig
        const dataConfig = this.config.dataConfig
        const dataConfigList = this.dataConfigList
        const interactionConfig = this.config.interactionConfig
        const datasetArray = [] as Array<Record<string, any>>
        const finalResultDetails = [] as Array<ReportElementType>
        let cnt = 0
        const expected = deviceConfig.length * dataConfigList.length * (1 + interactionConfig.map(v => v.times).reduce((a, b)=> a+b, 0))
        store.commit('startRunningTest', expected)
        const start = Date.now()
        for (const [deviceIndex, device] of deviceConfig.entries()) {
            // outer loop for device
            const svg = createSvg(divId, `test_svg`)
            for (const [datasetIndex, dataConfigInstance] of dataConfigList.entries()) {
                // mid loop for data case
                const dataset = getNewDataset(this.dataset, dataConfigInstance)
                datasetArray.push([])
                const error = this.test(svg, dataset, device.width, device.height)
                cnt++
                store.commit('updateProgress', cnt)
                if (error != '') {
                    // no bother for runtime error
                    console.log('runtime error!', error)
                        finalResultDetails.push({
                            datasetIndex,
                            deviceIndex,
                            interactionIndex: -1,
                            hasError: true,
                            details: { error }
                        })
                } else {
                    const staticChartDetector = new ChartDetector(svg.outerHTML, svg, false, false, false, detectConfig, this.bindings)
                    await staticChartDetector.runTest().then(svgChecklist => {
                        finalResultDetails.push({
                            datasetIndex,
                            deviceIndex,
                            interactionIndex: -1,
                            thumbnail: staticChartDetector.thumbnail,
                            hasError: false,
                            details: ErrorCounter.convertBooleanToNumber(svgChecklist)
                        })
                    })
                    
                    for (const interactionIndex in interactionConfig) {
                        const interactCase = interactionConfig[interactionIndex]
                        const counter = new ErrorCounter()
                        for (let randomTestIdx = 0; randomTestIdx < interactCase.times; randomTestIdx ++) {
                            const error = this.testWithInteraction(svg, dataset, device, interactCase, Runner.getSeed(interactCase.seed, randomTestIdx))
                            cnt ++
                            store.commit('updateProgress', cnt)
                            if (error != '') {
                                counter.updateError(randomTestIdx, error)
                            } else {
                                const chartdetector = new ChartDetector(svg.outerHTML, svg, false, false, false, detectConfig, this.bindings)
                                await chartdetector.runTest().then(v => {
                                    counter.update(v)
                                })
                            } 
                        }                 
                        finalResultDetails.push({
                            datasetIndex,
                            deviceIndex,
                            interactionIndex: Number(interactionIndex),
                            hasError: counter.errorCount != 0,
                            details: counter.stat
                        })
                    }
                }
                
            }
        }

        const end = Date.now()
        console.log((end - start)/60000, 'minutes')
        store.commit('endRunningTest')
            return {
                datasetArray,
                dataConfigArray: dataConfig.dataConfigList,
                deviceArray: deviceConfig,
                interactionArray: interactionConfig,
                results: finalResultDetails
            }

    }

    static getSeed(interactionSeed: number, repeat: number): string {
        return `interact_${interactionSeed}_${repeat}`
    }

    static getInstancePreview(
        svg: SVGElement,
        code: string,
        deviceConfig: DeviceSettingType,
        dataset: Record<string, any>[],
        interactionConfig: InteractionConfig,
        seed='',
        bindings: EventBinding[] = [],
        dependencies={},
        timeout=10000
    ):string {
        const height = deviceConfig.height
        const width = deviceConfig.width
        const svgEle = d3.select(svg)
        svgEle.attr('width', width).attr('height', height)
        const sheet = getDependencySheet(dependencies)
        const sandbox = vm.createContext({
            ...sheet,
            data: dataset,
            svg: svg,
            width: width,
            height: height,
            error: '',
            sequence: interactionConfig.sequence,
            bindings: bindings,
            errorMsg: '',
            keep: interactionConfig.keep,
            seed: seed,
            eventTriggerFunc: eventTriggerFunc
        })
        const insertCode = seed == '' ? '' : `eventTriggerFunc(svg, sequence, bindings, keep, errorMsg, seed)`
        try {
            vm.runInNewContext(Runner.getTestCode(code, '', insertCode), sandbox, {
                timeout: timeout,
                microtaskMode: 'afterEvaluate' 
            })
        } catch(e) {
            return 'timeout'
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const errorMsg = sandbox.error + sandbox.errorMsg as string
        return errorMsg
    }


    /**
     * Destroy middle results from the runner, inclyding the style sheet, div, canvas, and svg
     */
    public destroy(): void {
        document.getElementById(this.styleEleId)?.remove()
        this.isDestroyed = true
    }
}

const createSvg = function (divId: string, className: string): SVGElement {
    const divEle = d3.select(`#${divId}`).html('')
    const svgDivEle = divEle.append('div')
    return svgDivEle
    .append('div')
    .style('position', 'relative')
    .style('height', '50vh')
    .append('svg')
    .attr('xlmns', 'http://www.w3.org/2000/svg')
    .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
    .style('position', 'absolute')
    .style('x', '0px')
    .style('y', '0px')
    .classed(className, true)
    .node() as unknown as SVGElement
}

const eventTriggerFunc = function (svg: SVGElement, sequence: Array<InteractionAtomType>, bindings: EventBinding[], errorMsg: string, keep=false, seed:string) {
    const rand = randomSeed.create(seed)
    if (sequence.length == 0) return false
    const _event = sequence[0].event
    const _binding = bindings.filter(v => v.event == _event)[0]
    const _classNames = _binding.classNames
    const _classNameIdx = rand.intBetween(0, _classNames.length-1)
    const _alias = sequence[0].element? sequence[0].element : `.${_classNames[_classNameIdx]}`
    let _ele: HTMLElement
    sequence.forEach((seq: InteractionAtomType, seqIdx: number) => {
        let alias = _alias
        const event = seq.event
        if (seqIdx != 0 && keep === false) { // keep randomly peeking elements
            const binding = bindings.filter(v => v.event == event)[0]
            const classNames = binding.classNames
            const classNameIdx = rand.intBetween(0, classNames.length - 1)
            alias = seq.element? seq.element : `.${classNames[classNameIdx]}`
        }
        seq.element = alias
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const group_elements = Array.from(d3.select(svg).selectAll(alias)._groups[0]) as Array<HTMLElement>
            const _randomIdx = rand.intBetween(0, group_elements.length-1)
            if(seqIdx == 0 ) {
                _ele = group_elements[_randomIdx]
            }
            const ele = keep? _ele : group_elements[_randomIdx]          
            d3.select(ele).dispatch(`${event}`)
            // console.log('debug', alias, _randomIdx, group_elements.length)
        } catch (e: any) {
            console.warn(e)
            errorMsg = e
            return false
        }
    })
} 

export {
    ReportType,
    ReportElementType,
    ErrorTestResultType,
    AggregatedSVGChecklist,
    Runner
}
