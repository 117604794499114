import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "stat-panel"
}
const _hoisted_2 = { class: "report-stat-div" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_ctx.table.length>0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_card, {
          title:    `Report: ${_ctx.stat.times} test runs`,
          bordered: false,
          style: {"width":"100%"},
          size: "small"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_descriptions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_descriptions_item, { label: "runtime errors" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.stat.runtimeErrors), 1)
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stat.displayList, (item) => {
                    return (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: `stat_${item.error}`,
                      label: item.error.replaceAll('_', ' ')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.value), 1)
                      ]),
                      _: 2
                    }, 1032, ["label"]))
                  }), 128))
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ]))
    : _createCommentVNode("", true)
}