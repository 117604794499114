<template>
  <a-modal 
    :visible="visible" 
    :title="null"
    :footer="null"
    :width="1200"
    @cancel="handleCancel"
  >

    <h2> Walk through </h2>
     <div class="case-list">
      <div class="case" v-for="c in toyCaseList" :key="c.name">
        <div class="case-thumbnail">
          <img :src="c.img" @click="handleUploadCase(c)">
        </div>
        <div class="case-title">
          {{ c.name }}
          <a-tooltip v-if="c.link" title="source" placement="right">
            <a class="src-info" target="_blank" :href="c.link"> <ExclamationCircleOutlined/> </a>
          </a-tooltip>
        </div>
        <div class="case-desc">
         <span> {{ c.description}} </span>
        </div> 
      </div>
    </div>



    <h2>Case Study </h2>
    <div class="case-list">
      <div class="case" v-for="c in caseList" :key="c.name">
        <div class="case-thumbnail">
          <img :src="c.img" @click="handleUploadCase(c)">
        </div>
        <div class="case-title">
          {{ c.name }}
          <a-tooltip v-if="c.link" title="source" placement="right">
            <a class="src-info" target="_blank" :href="c.link"> <ExclamationCircleOutlined/> </a>
          </a-tooltip>
        </div>
        <div class="case-desc">
         <span> {{ c.description}} </span>
        </div> 
      </div>
    </div>
    </a-modal>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import axios from 'axios'
import { key } from '../store'
import { useStore } from 'vuex'
import * as d3Dsv from 'd3-dsv'
import { TestConfigFile } from '../assets/vis-testing/config'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
const prefixCase = './cases/'
const prefixStudy = './participants/'
export default defineComponent({
  props: {
    visible: { type: Boolean },
  },
  components: {
    ExclamationCircleOutlined
  },
  setup(props, context) {
    const toyCaseList: any[] = [{
        img: './cases/sunburst/thumbnail-new.png', 
        name: 'Sunburst', 
        description: 'The file structure of a software', 
        srcCss: "",
        srcCfg: './cases/sunburst/config.json', 
        srcData: './cases/sunburst/data.csv',
        srcJs: './cases/sunburst/sunburst.js'
      },  {
        img: './cases/controlled-study/thumbnail.png', 
        name: 'Flower Chart', 
        description: 'An infographics of international index', 
        link: 'https://observablehq.com/@a10k/flower-chart-visual',
        srcCfg: './cases/controlled-study/config.json', 
        srcCss: './cases/controlled-study/style.css',
        srcData: './cases/controlled-study/dataset.csv',
        srcJs: './cases/controlled-study/script.js'
      }, {
        img: './cases/useless/participants/zhen-news/screenshot.png', 
        name: 'Ridgeline plot', 
        description: 'A ridgeline plot of Twitter trends', 
        srcCss: "",
        srcCfg: './cases/useless/participants/zhen-news/config.json', 
        srcData: './cases/useless/participants/zhen-news/data.csv',
        srcJs: './cases/useless/participants/zhen-news/line.js'
      }
    ]
    const caseList: any[] = [{
        img:  './cases/weather/weather.png',
        name: 'Weather Dashboard',
        description: 'Annual weather in the west coast',
        link: 'https://observablehq.com/@analyzer2004/west-coast-weather-from-seattle-to-san-diego',
        srcCss: '',
        srcCfg: './cases/weather/config.json',
        srcData: './cases/weather/portland_cleaned.csv',
        srcJs: './cases/weather/weather.js'
      }, {
        img:  './cases/poppy-field/preview.png',
        name: 'Poppy Field',
        link: 'http://poppyfield.org/',
        description: 'Human life lost in war since 1900',
        srcCss: './cases/poppy-field/style.css',
        srcCfg: './cases/poppy-field/config.json',
        srcData: './cases/poppy-field/data_v3.csv',
        srcJs: './cases/poppy-field/plot.js'
      },  {
        img: './cases/VAST_2021_peng/preview.png', 
        name: 'Stacked Area Chart', 
        description: 'VAST\'21- Microblog type distribution', 
        link: "http://fduvis.net/MC3/",
        srcCss: "",
        srcCfg: './cases/VAST_2021_peng/config.json', 
        srcData: './cases/VAST_2021_peng/data.csv',
        srcJs: './cases/VAST_2021_peng/timeline.js'
      }, {
        img: './cases/VAST_2020_ca2/vast-ca2-activityChart/preview.png', 
        name: 'CA2: Activity Chart', 
        description: 'VAST\'20-CA2 Graph visualization', 
        link: 'https://vast-2020.vercel.app/#/',
        srcCss: "",
        srcCfg: './cases/VAST_2020_ca2/vast-ca2-activityChart/config.json', 
        srcData: './cases/VAST_2020_ca2/vast-ca2-activityChart/data.csv',
        srcJs: './cases/VAST_2020_ca2/vast-ca2-activityChart/activityChart.js'
      },
      {
        img: './cases/VAST_2020_ca2/vast-ca2-lineChart/thumbnail.png', 
        name: 'CA2: Line Chart', 
        link: 'https://vast-2020.vercel.app/#/',
        description: 'VAST\'20-CA2 Activity frequency', 
        srcCss: "",
        srcCfg: './cases/VAST_2020_ca2/vast-ca2-lineChart/config.json', 
        srcData: './cases/VAST_2020_ca2/vast-ca2-lineChart/data.csv',
        srcJs: './cases/VAST_2020_ca2/vast-ca2-lineChart/lineChart.js'
      }, 
      {
        img: './cases/VAST_2020_ca2/vast-ca2-barChart/preview.png', 
        name: 'CA2: Bar Chart', 
        link: 'https://vast-2020.vercel.app/#/',
        description: 'VAST\'20-CA2 Personnel activity stat', 
        srcCss: "",
        srcCfg: './cases/VAST_2020_ca2/vast-ca2-barChart/config.json', 
        srcData: './cases/VAST_2020_ca2/vast-ca2-barChart/data.csv',
        srcJs: './cases/VAST_2020_ca2/vast-ca2-barChart/barChart.js'
      }, {
        img: './cases/VAST_2020_ca2/vast-ca2-matrix/preview.png', 
        name: 'CA2: Matrix', 
        description: 'VAST\'20-CA2 Personnel comparison', 
        link: 'https://vast-2020.vercel.app/#/',
        srcCss: "",
        srcCfg: './cases/VAST_2020_ca2/vast-ca2-matrix/config.json', 
        srcData: './cases/VAST_2020_ca2/vast-ca2-matrix/data.csv',
        srcJs: './cases/VAST_2020_ca2/vast-ca2-matrix/matrix.js'
      }, {
        img: './cases/VAST_2020_huyen/case_VAST2020mc2_matrix/preview.png', 
        name: 'Object Distribution Matrix', 
        description: 'VAST\'20 Object distribution matrix', 
        srcCss: "",
        link: "https://github.com/huyen-nguyen/VAST2020mc2",
        srcCfg: './cases/VAST_2020_huyen/case_VAST2020mc2_matrix/config.json', 
        srcData: './cases/VAST_2020_huyen/case_VAST2020mc2_matrix/data.csv',
        srcJs: './cases/VAST_2020_huyen/case_VAST2020mc2_matrix/matrix.js'
      }, {
        img: './cases/VAST_2020_huyen/case_VAST2020mc2_overview/preview.png', 
        name: 'Grouped Bar Chart', 
        description: 'VAST\'20 Classification result overview', 
        link: "https://github.com/huyen-nguyen/VAST2020mc2",
        srcCss: "",
        srcCfg: './cases/VAST_2020_huyen/case_VAST2020mc2_overview/config.json', 
        srcData: './cases/VAST_2020_huyen/case_VAST2020mc2_overview/data.csv',
        srcJs: './cases/VAST_2020_huyen/case_VAST2020mc2_overview/overview.js'
      }, {
        img: './cases/VAST_2020_huyen/case_VAST2020mc2_pattern/preview.png', 
        name: 'Pattern Bar Chart', 
        description: 'VAST\'20 Pattern', 
        link: "https://github.com/huyen-nguyen/VAST2020mc2",
        srcCss: "",
        srcCfg: './cases/VAST_2020_huyen/case_VAST2020mc2_pattern/config.json', 
        srcData: './cases/VAST_2020_huyen/case_VAST2020mc2_pattern/data(5).csv',
        srcJs: './cases/VAST_2020_huyen/case_VAST2020mc2_pattern/pattern.js'
      }, {
        img: './cases/VAST_2020_wong/chart1/preview.png', 
        name: 'Sorted Bar Chart (#1)', 
        description: 'VAST\'20 Sorted Bar Chart', 
        link: 'https://github.com/JakobWong/VAST2020',
        srcCss: "",
        srcCfg: './cases/VAST_2020_wong/chart1/config.json', 
        srcData: './cases/VAST_2020_wong/chart1/i3_new_data.csv',
        srcJs: './cases/VAST_2020_wong/chart1/chart1.js'
      },
      {
        img: './cases/VAST_2020_wong/chart2/preview.png', 
        name: 'Scatter Plot (#2)', 
        description: 'VAST\'20 Scatter plot', 
        link: "https://github.com/JakobWong/VAST2020",
        srcCss: "",
        srcCfg: './cases/VAST_2020_wong/chart2/config.json', 
        srcData: './cases/VAST_2020_wong/chart2/i3_new_data.csv',
        srcJs: './cases/VAST_2020_wong/chart2/chart2.js'
      }, {
        img: './cases/VAST_2020_wong/chart4/preview.png', 
        name: 'Adjacency Matrix (#4)', 
        description: 'VAST\'20 Ontology node-link diagram,',
        link: "https://github.com/JakobWong/VAST2020",
        srcCss: "",
        srcCfg: './cases/VAST_2020_wong/chart4/config.json', 
        srcData: './cases/VAST_2020_wong/chart4/i3_new_data.csv',
        srcJs: './cases/VAST_2020_wong/chart4/chart4.js'
      }, {
        img: './cases/VAST_2017_gu/case_VAST2017_CarTypeBar/preview.png', 
        name: 'Car Type Distribution', 
        description: 'VAST\'17 Car type distribution', 
        srcCss: "",
        link: "https://georgegu1997.github.io/VAST-challenge-frontend/#/mc1-route",
        srcCfg: './cases/VAST_2017_gu/case_VAST2017_CarTypeBar/config.json', 
        srcData: './cases/VAST_2017_gu/case_VAST2017_CarTypeBar/data_new.csv',
        srcJs: './cases/VAST_2017_gu/case_VAST2017_CarTypeBar/CarTypeBar.js'
      }, {
        img: './cases/VAST_2017_gu/case_VAST2017_RouteDrawerSmall/preview.png', 
        name: 'Route Drawer', 
        description: 'VAST\'17 Route view', 
        link: "https://georgegu1997.github.io/VAST-challenge-frontend/#/mc1-route",
        srcCss: "",
        srcCfg: './cases/VAST_2017_gu/case_VAST2017_RouteDrawerSmall/config.json', 
        srcData: './cases/VAST_2017_gu/case_VAST2017_RouteDrawerSmall/sensor_position_1.csv',
        srcJs: './cases/VAST_2017_gu/case_VAST2017_RouteDrawerSmall/RouteDrawerSmallwithPath.js'
      }, 
    ]

    const caseListAbs = computed(() => {
      const prefix = prefixCase
      const cases = caseList.map(v => {
        const css = v.srcCss? prefix + v.srcCss : ''
        return {
          img: prefix + v.img,
          name: v.name,
          description: v.description,
          srcCfg: prefix + v.srcCfg,
          srcData: prefix + v.data,
          srcCss: css,
          srcJs: prefix + v.srcCss
        }
      })
      return cases
    })

    const handleCancel = (e: MouseEvent) => {
      context.emit("update:visible", !props.visible);
    }

    const store = useStore(key)

    const handleUploadCase = async (c: any) => {
      const hasCss = c.srcCss != ''
      let cfg = (await axios.get(c.srcCfg)).data
      let data = (await axios.get(c.srcData)).data
      let js = (await axios.get(c.srcJs)).data
      const tasks = [cfg, data, js]
      if(hasCss) tasks.push((await axios.get(c.srcCss)).data)
      Promise.all(tasks).then((res) => {
        const _cfg = res[0]
        const _data = res[1]
        const _js = res[2]
        const dataset = d3Dsv.csvParse(_data, d3Dsv.autoType) as any
        const config = new TestConfigFile(_cfg)
        store.commit('updateDataset', dataset)
        store.commit('updateConfig', config)
        store.commit('updateCode', _js)
        const _css = hasCss? res[3] : ''
        store.commit('updateStyle', _css)
        store.commit('updateUiFlag')
        context.emit("update:visible", !props.visible);
      })
    }

    return {
      caseList,
      toyCaseList,
      handleCancel,
      handleUploadCase,
    }
  },
})
</script>

<style lang="scss" scoped>
.case-list {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: flex-start;
}

.src-info {
  font-size: 14px;
}

.case {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px 12px;

  .case-thumbnail {
    width: 250px;
    height: 250px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
    img {
        width: 250px;
       
        vertical-align: middle;
        border-style: none;
    min-width: 250px;
    border-radius: 5px;
    }
  }
  .case-title {
    font-size: 20px;
  }
  .case-desc {
    font-size: 15px;
    max-width: 250px;
  }
}

</style>