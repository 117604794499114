<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: 'Lato', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  .config-code {
    margin-top: 28px;
  }
  .stat-panel {
    margin-top: 0; //60px
  }

}
body {
  margin: 0 !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
    // background-color: rgb(233, 233, 233);
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 9px rgba(247, 247, 247, 0.788);
}
</style>
