<template>
<div class="preview-container">
    <div class="preview-wrapper">
    <div class="preview-svg-container center-container">
        <a-row :style="{width: `${ 2 * svgWidth}px`}" justify="center" align="middle">
          <a-col :span="12" >
              <a-typography-title :level="5">Static</a-typography-title>
              <div class="preview-static-svg-container" :style="{width: `${ svgWidth}px`, height: `${ svgHeight + 10}px`}">
                  <svg></svg>
                </div>
                 &nbsp;
          </a-col>
          <a-col :span="12">
              <a-typography-title v-if="entry.interactionIndex!=-1" :level="5">Re-Run</a-typography-title>
              <div class="preview-dynamic-svg-container" :style="{width: `${ svgWidth}px`, height: `${ svgHeight + 10}px`}">
                  <svg></svg>
              </div>
               &nbsp;
          </a-col>

    </a-row>
        </div>

    <div class="preview-result">
        <a-row type="flex" align="middle">
            <a-col :span="3">
                <span v-if="showAnnotation"> <EyeOutlined   @click="updateAnnotation"/> Show Error </span>
                <span v-else> <EyeInvisibleOutlined  @click="updateAnnotation" /> Hide Error </span>
            </a-col>
             <a-col :span="21">
                <div class="tag-container">
                    <span> <b>⨉:&nbsp;&nbsp;</b></span>
                    <template v-for="(item, eid) in errorList.filter(v => v.value!=0)"  :key="`tag_bad_${eid}`">
                        <span class="bad-tag result-tag" 
                        :style="{'border-color': `${getHighlightLegendColor(item.error)}`}" 
                            @click="updateHighlight(item.error)"
                            @dblclick="updateHighlight('all')">
                            <img src=""/>  {{ getAbbr(item.error).replaceAll('_', ' ')  }}:&nbsp; {{ item.value }}       
                        </span>
                        <span class="legend-tag" 
                            @click="updateHighlight(item.error)"
                            @dblclick="updateHighlight('all')"
                            :style="{ 'background': getHighlightLegendColor(item.error)}"
                            > 
                            &nbsp;&nbsp;&nbsp;
                        </span>
                    </template>
                </div>
                <div  class="tag-container">
                    <span> <b>✓:&nbsp;&nbsp;</b></span>
                    <template v-for="(item, eid) in errorList.filter(v => v.value==0)"  :key="`tag_good_${eid}`">
                            <span class="good-tag result-tag">
                                {{ getAbbr(item.error).replaceAll('_', ' ')  }} 
                            </span>
                    </template>
                </div>
            </a-col>
        </a-row>
    </div>
    <a-divider orientation="left">Diagnosis</a-divider>
    <div>
        <a-button type="dashed" @Click="() => {sendQuery()}">Consult GPT</a-button>
        <JsonViewer :value="gptJson" :key="forceUpdateKey" copyable boxed sort theme="light"/>
    </div>
       <a-divider orientation="left">Configuration</a-divider>
    <div class="preview-config left-container">
        <div>
             <span><DesktopOutlined/> <b>Device:&nbsp;</b></span>
            <span> {{ entry.deviceStr }} </span>
        </div>

        <div>
           <span>  <DatabaseOutlined/><b> Dataset:&nbsp;</b> </span>
            <span> No.{{ entry.datasetIndex + 1 }} — {{ entry.datasetStr }} </span>&nbsp;&nbsp;
            <span> size = {{ dataset.length }} </span> 
        </div>
        <div class="second-layer">
            <details>
                <summary> dataset details </summary>
                <a-table :columns="datasetPreviewColumns" 
                :data-source="datasetAttrTable" 
                size="small" 
                :pagination="{ hideOnSinglePage: true, size: `small`, pageSize: 20}" 
                >
                <template #previewDistributionColumn="{ record }">
                    <div :style="{ width: '100%', height: '25px'}">
                        <svg class="dist-svg" width="100%" height="100%"  preserveAspectRatio="xMidYMid meet">
                        <rect v-for="(bin, bidx) in record.bins" :key="bidx" :x="bidx * 8"  width="8" :y="20-bin/Math.max(...record.bins)*20" :height="bin/Math.max(...record.bins)*20">
                        </rect>
                        </svg>
                    </div>
                </template>
                </a-table>     
            </details>
        </div>
    </div>
      <a-divider orientation="left">Simulations</a-divider>
    <div class="preview-interaction left-container">
        <div>
              <span><UserOutlined/><b> Interaction:&nbsp;</b> </span>
            <span> {{ entry.interactStr }} </span>
        </div>
        <div class="second-layer">
          <details v-if="entry.interactionIndex!=-1">
            <summary> Interaction details </summary>
            <p> 
                {{ JSON.stringify(result.interactionArray[entry.interactionIndex].sequence) }}
            </p>
        </details>
        </div>
        <a-list :grid="{ gutter: 16, column: 4 }" :data-source="simulations">
            <template #renderItem="{ item, index }">
                <Card :record="item" :index="index"/>
            </template>
            
        </a-list>
    </div>
</div>
</div>
</template>
<script lang="ts">
/* eslint-disable */
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue"
import Card from './Card.vue'
import { useStore } from 'vuex'
import { key } from '../../store'
import { DesktopOutlined, DatabaseOutlined, UserOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons-vue'
import {  ErrorType, ErrorDetectConfigAtomType, getDataAttrTable, getNewDataset, InteractionConfig, mapOfErrorAbbr } from "../../assets/vis-testing/config"
import { Runner } from "../../assets/vis-testing/exec"
import { datasetPreviewColumns, ratioColorScale, TableDataType } from '../../assets/ui-config'
import { ChartDetector } from "../../assets/vis-testing/detect"
import * as d3 from "d3"
import * as d3Color from "d3-color"
import OpenAI from "openai";
import { structuredQueryGPT, freeformQueryGPT } from "@/assets/openai"
import { ChatCompletionMessageParam } from "openai/resources"
import Fuse from 'fuse.js'
const staticDivClass = `preview-static-svg-container`
const dynamicDivClass = `preview-dynamic-svg-container`
const reasonLayerClass = `reason-layer`
export default defineComponent({
    name: 'Preview',
    props: {
        entry: Object
    },
    components: {
        Card,
        DesktopOutlined,
        DatabaseOutlined,
        UserOutlined,
        EyeInvisibleOutlined,
        EyeOutlined,
    },
    setup(props, context) {
        const simulations = ref([] as any)
        const store = useStore(key)
        const result = computed(() => store.state.testResult)
        const code = computed(() => store.state.updateCode)
        const config = computed(() => store.state.config)
        const dataConfig = ref({})
        const gptJson = reactive([])
        const bindings = computed(() => store.state.bindings)
        const showAnnotation = ref(true)
        const datasetAttrTable = ref([] as TableDataType[])
        const dataset = ref([] as any)
        const svgWidth =  ref(300)
        const svgHeight =  ref(500)
        const messages = ref([] as Array<{role: 'user' | 'assistant', content: string}>)
        const errorList = computed(() => {
            // !!! to implemented: show code
            const entry = props.entry
            if(!entry) return [] as Array<{error: string, value: number}>
            return Object.keys(entry.details)
            .filter(v => v != 'valid_total')
              .map(v => {
                return {
                    error: v,
                    value: entry.details[v]
                }
            }).sort((a, b) => b.value - a.value)
        })
        watch(()=>props.entry, function (newVal) {
            const res = result.value
            if((!res) || (!newVal)) return
            dataConfig.value =  result.value.dataConfigArray[newVal.datasetIndex]
            dataset.value = getNewDataset(store.state.dataset, result.value.dataConfigArray[newVal.datasetIndex])
            datasetAttrTable.value = getDataAttrTable(dataset.value)
            svgWidth.value = result.value.deviceArray[newVal.deviceIndex].width 
            svgHeight.value = result.value.deviceArray[newVal.deviceIndex].height 
            setTimeout(() => runSimulation(), 500)
        })

        const runSimulation = async function () {
            if(!props.entry) return
            const { svg, _svg } = clearCanvas()
            const deviceConfig = result.value.deviceArray[props.entry.deviceIndex]
            const isStatic = props.entry.interactionIndex === -1
            const interactConfig = isStatic ? InteractionConfig.nullConfig : result.value.interactionArray[props.entry.interactionIndex]
            Runner.getInstancePreview(svg, code.value, deviceConfig, dataset.value, interactConfig, '0', [],store.state.config.dependencies, store.state.config.errorDetection.timeout.threshold)
            const chartDetector = new ChartDetector(svg.outerHTML, svg, true, true, true, config.value.errorDetection, bindings.value)
            await chartDetector.runTest()
            const svgSelection = d3.select(svg)
            const width = parseFloat(svgSelection.attr('width'))
            const height = parseFloat(svgSelection.attr('height'))
            // if (width > 300) {
            //     setTimeout(()=> {
            //         d3.select(svg).attr('width', 300).attr('height', 300 * height / width)
            //         d3.select(svg.parentElement).select('.reason-layer').attr('width', 300).attr('height', 300 * height / width).attr('perserveAspectRatio', 'xMidYMid meet')
            //     }, 500)
                  
            // }
            if (isStatic) return
            // run simulations for interactions
            for (let sidx = 0; sidx < interactConfig.times; sidx ++) {
                const seed = Runner.getSeed(interactConfig.seed, sidx)
                Runner.getInstancePreview(_svg, code.value, deviceConfig, dataset.value, interactConfig, seed, bindings.value, store.state.config.dependencies,  store.state.config.errorDetection.timeout.threshold)
                const detector = new ChartDetector(_svg.outerHTML, _svg, true, true, true, config.value.errorDetection, bindings.value)
                await detector.runTest().then((v) => {
                    simulations.value.push({
                        thumbnail: detector.thumbnail,
                        result: config.value.errorDetection.transformResult(v)
                    })
                })
            }
            // if (width > 300) {
            //     setTimeout(()=> {   
            //         d3.select(_svg).attr('width', 300).attr('height', 300 * height / width)
            //     d3.select(_svg.parentElement).select('.reason-layer').attr('viewbox', `0 0 ${width} ${height}`).attr('width', 300).attr('height', 300 * height / width)

            //     })
             
            // }
        }

        const clearCanvas = function() {
            const divEle = d3.select(`.${staticDivClass}`)
            const _divEle = d3.select(`.${dynamicDivClass}`)
            divEle.select(`.${reasonLayerClass}`).remove()
            _divEle.select(`.${reasonLayerClass}`).remove()
            const svg = divEle.select('svg').html('').node() as SVGElement
            svg.removeAttribute('style')
            const _svg = _divEle.select('svg').html('').node() as SVGElement
            _svg.removeAttribute('style')
            simulations.value = [] as Array<{thumbnail: string, result: any[]}>
            showAnnotation.value = true
            return { svg, _svg }
        }
        onMounted(() => {
            if(props.entry) {
                dataConfig.value = result.value.dataConfigArray[props.entry.datasetIndex]
                dataset.value = getNewDataset(store.state.dataset, result.value.dataConfigArray[props.entry.datasetIndex])
                datasetAttrTable.value = getDataAttrTable(dataset.value)
                svgWidth.value = result.value.deviceArray[props.entry.deviceIndex].width || 500
                svgHeight.value = result.value.deviceArray[props.entry.deviceIndex].height || 500
                // console.log('svg size', svgHeight.value, svgWidth.value)
            }
            setTimeout(() => runSimulation(), 500)
        })
        return {
            svgWidth,
            svgHeight,
            simulations,
            errorList,
            dataConfig,
            showAnnotation,
            mapOfErrorAbbr,
            config,
            dataset,
            gptJson,
            datasetAttrTable,
            datasetPreviewColumns,
            result,
            runSimulation,
            store
        }
    },
    data() {
        return {
            forceUpdateKey: 0
        
        }
    },
    methods: {
        async sendQuery() {
            const entry = this.entry
            const code = this.store.state.code
            const svg = d3.select(`.${staticDivClass}`).select('svg').html()
            const testResult = this.store.state.testResult
            // send query to GPT
            
            // @deprecated, used for azure openai
            // const endpoint = "https://hkust.azure-api.net";
            // const key = "858219a89b964950a81e5df1429d4d7e";
            
            const problemExplanation : Record<string, string> = {
                text_overlap: "Text overlap means two or more texts overlap with each other.",
                mark_overlap: "Mark overlap means two or more figures overlap with each other.",
                text_mark_overlap: "Text-mark overlap means one or more texts overlap with figures.",
                contrast: "Contrast error points out that the contrast between the text and the background is not enough.",
                small_text: "Small text refers to the text is too small to be read clearly.",
                small_mark: "Small mark refers to the mark is too small to be interacted with.",
                undesired_value: "The value in running the code is NaN.",
                overflow: "Overflow means that some figures or texts are out of the canvas.",
                timeout: "Timeout indicates that the programme runs too long to get the result.",
            };
            const errors = []
            if (entry && entry.details){
                for (const key in entry.details) {
                    if (entry.details[key] > 0 && key in problemExplanation) {
                        errors.push(key)
                    }
                }
            }
            
            const problemExplanationMessage = errors.map((v) => problemExplanation[v]).join(" ");
            const problemMessage = "The problems of " + errors.join(", ") + " occur in code."
 

            const msgs = [{
                "role": "system",
                "content": `You are GPT-4, an intelligent assistant. Please help a visualization developer test the robustness of a visualization 
                program written in d3.js version 7.0.0.`
            },{
                "role": "user",
                "content": `Here is the code:\n\n ${code} \n\n${problemMessage} \n\n${problemExplanation} \n\nPlease analyze the issue of my code.`
            }] as ChatCompletionMessageParam[]

           
            const lastMsg = [{
                "role": "user",
                "content": `Yes indeed. While analyzing the problem and the code, I wonder if you can help me classify the problem?
                    Specifically, I want to know if the problem is caused by transform, scaling, visual mapping, annotation or generic problem? Or perhaps you think
                    the problem does not belongs to any of the above categories, then can you help me provide a new category?
                    Please answer in JSON format: 
                    [{
                        "problem" : "describe the problem with the code"
                        "code" : ["strictly copy the lines from original code that need to be changed. ONLY DO COPY! DO NOT CHANGE ANYTHING!"]
                        "suggestion" : "general suggestions to the developer based on the code"
                        "revision" : "the revised code after the suggestion is applied"
                        "category" : "the category of the problem"
                    }]
               `
            }] as ChatCompletionMessageParam[]

            // avoid accidental use of GPT
            freeformQueryGPT(msgs).then((chatCompletion) => {
                const val = chatCompletion as OpenAI.Chat.Completions.ChatCompletion
                const analysis = val.choices[0].message.content as string
                msgs.push(val.choices[0].message)
                msgs.push(...lastMsg)
                structuredQueryGPT(msgs).then((chatCompletion) => {
                const val = chatCompletion as OpenAI.Chat.Completions.ChatCompletion
                msgs.push(val.choices[0].message)
                const res = val.choices[0].message.content as string
                const json_obj = JSON.parse(res)
                console.log('GPT response', json_obj)
                this.gptJson = json_obj
                this.forceUpdateKey += 1
        
                const resultCode = json_obj["problem"]
                const lineCount = resultCode.split(/\r?\n/).length
                const lineSearchResult = fuse.search(resultCode.trim())
                const lineNumbers = []
                if (lineSearchResult.length > 0) {
                    lineSearchResult.forEach((v) => {
                        // returns many matches, only take the top matches according to line count
                        if (lineNumbers.length > lineCount) return
                        lineNumbers.push(v.refIndex + 1)
                    })
                }
                
            })

            }, (err) => console.log(err + ""))

            // Fuse js used for fuzzy search
            const fuse = new Fuse(code.split(/\r?\n/), {
                includeMatches: true,
                threshold: 0.4, 
                distance: 30,
            });

            
            console.log('send query', entry)
            
        },
        getErrorPillColor(pill: {error: string, value: number}):string {
            if (pill.value === 0) return 'green'
            return ratioColorScale(pill.value)
        },
        getAbbr(error: string):string {
            return error
        },
        updateHighlight(error: ErrorType):void {
            ChartDetector.highlightError(d3.selectAll(`.${reasonLayerClass}`), error)
        },
        getHighlightLegendColor(error: ErrorType):string {
            const abbr = mapOfErrorAbbr.get(error)
            if((!this.config.errorDetection)||(!abbr)) return ""
            const errorConfig = this.config.errorDetection[abbr] as ErrorDetectConfigAtomType
            if(!errorConfig) return ""
            const color = errorConfig.highlightColor
            const colorObj = d3Color.color(color) as unknown as d3Color.RGBColor
            colorObj.opacity = 0.4
            return colorObj + ""
        },
        updateAnnotation():void {
            this.showAnnotation = !this.showAnnotation
            const divEle = d3.select(`.${staticDivClass}`)
            const _divEle = d3.select(`.${dynamicDivClass}`)

            if (this.showAnnotation) {
                divEle.select(`.${reasonLayerClass}`).style('display', '')
                _divEle.select(`.${reasonLayerClass}`).style('display', '')
            }
            else {
                 divEle.select(`.${reasonLayerClass}`).style('display', 'none')
                _divEle.select(`.${reasonLayerClass}`).style('display', 'none')
            }
        }
    }

})
</script>
<style lang="scss">
.center-container {
    text-align: middle;
}
.second-layer {
    margin-left: 3vw;
}
.ant-modal-body {
    overflow-y: scroll;

}
.preview-result {
    margin-bottom: 1vh;
}
.preview-dynamic-svg-container {
    display: relative;
    svg {
        position: absolute;
        x: 0px;
        y: 0px;
    }
    margin-bottom: 10px;
}
.preview-static-svg-container {
    display: relative;
    svg {
        position: absolute;
        x: 0px;
        y: 0px;
    }
    margin-bottom: 10px;
}
.ant-divider-horizontal.ant-divider-with-text {
    margin-bottom: 0 !important;
}
.legend-tag {
    cursor: pointer;
    border-radius: px !important;
    margin-right: 5px !important;
}
.tag-container {
    margin-top: 1em;
    .result-tag {
        padding: 1px 4px;
        border-width: 2px;
        border-style: solid;
        border-radius: 5px;
        &.bad-tag {
            margin-right: 0px;
            cursor: pointer;
        }
        &.good-tag {
            border-color: #dadada;
            margin-right: .8em;
        }
    }
}

</style>