<template>
    <div v-if="table.length>0" class="stat-panel">
        <a-card :title="   `Report: ${stat.times} test runs`" :bordered="false" style="width: 100%" size="small">
        <div class="report-stat-div">
            <a-descriptions>
            <a-descriptions-item label="runtime errors"> {{ stat.runtimeErrors }} </a-descriptions-item>
            <template v-for="item in stat.displayList" :key="`stat_${item.error}`">
                <a-descriptions-item :label="item.error.replaceAll('_', ' ')">
                    {{ item.value }}
                </a-descriptions-item>
            </template>       
        </a-descriptions>
        </div>
        </a-card>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { UITableDatum, getStat } from '../../assets/ui-config'
import { useStore } from 'vuex'
import { key } from '../../store'
export default defineComponent({
    name: 'Statistics',
    props: {
        table: {
            default: [],
        }
    },
    setup(props) {
        const store = useStore(key)
        const table = props.table as unknown as UITableDatum[]
        const stat = computed(() => getStat(table,  store.state.config.errorDetection))
        return {
            stat,
        }
    }
})
</script>
<style lang="scss">
.report-stat-div {
    padding: 5px 24px;
}

</style>