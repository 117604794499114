import { ErrorDetectConfig, ErrorDetectConfigType, TestConfigFile } from "./config"

/**
 * This is the doc comment for auto.ts.
 * @module vis-testing
 * Generate config file based on known properties.
 */

// class AutoConfigOptions {

// }
// const getDefaultConfig = function (options: Partial<AutoConfigOptions>): TestConfigFile {

// }

// const getEmptyConfig = function (): TestConfigFile {
    
// }

// const getCustomConfig = function (options: Partial<AutoConfigOptions>): TestConfigFile {

// }

const getVisibleConfig = function (file: TestConfigFile): Record<string, any> {
    const result = {} as Record<string, any>
    result.deviceConfig = file.deviceConfig.map(v => {
        return {
            label: v.label,
            width: v.width,
            height: v.height
        }
    })
    result.dataConfig = {
        extreme: file.dataConfig.extreme.map(v => {
            return {
                dimension: v.dimension,
                value: v.value
            }
        }),
        perturb: file.dataConfig.perturb.map((v => {
            return {
                dimension: v.dimension,
                value: v.value
            }
        })),
        scale: file.dataConfig.scale.map((v => {
            return {
                dimension: v.dimension,
                value: v.value
            }
        })),
        sample: file.dataConfig.sample.map( v => v),
        shuffle: file.dataConfig.shuffle,
        static: file.dataConfig.static
    }
    result.interactionConfig = file.interactionConfig.map((v) => {
        return {
            tag: v.tag,
            times: v.times,
            keep: v.keep,
            sequence: v.sequence.map(n => n)
        }
    })
    result.dependencies = file.dependencies
    result.errorDetection = {} as Record<string, any>
    Object.keys(file.errorDetection).forEach(err => {
        const key = err as keyof ErrorDetectConfigType
        result.errorDetection[err]  = {
            isOn: file.errorDetection[key].isOn
        }
        if ((key !== 'error') && (key !== 'invalidValue')) {
            result.errorDetection[err].threshold = file.errorDetection[key].threshold
        }
    })
    return result
}

// const reverseVisibleConfig = function (file: Partial<TestConfigFile>): TestConfigFile {

// }

export { getVisibleConfig }