<template>
<div id="user-code-editor">
    <VCodeMirror
    :value="code"
    theme="white"
    :options="options"
    @update:value="change"
  />
    </div>
    
</template>
<script lang="ts">
import { defineComponent,  ref, watch } from 'vue'
import { VCodeMirror } from "vue3-code-mirror"

export default defineComponent({
    name: 'CodeEditor',
    components: {
        VCodeMirror
    },
    props: {
        rawCode: {
            type: String,
            default: ''
        }
    },
    emit: ['update'],
    setup(props, context) {
        const code = ref(props.rawCode)
        watch(props, () => {
          code.value = props.rawCode
        })
        const change = function(e: Event):void {
            context.emit('update', e)
        }
        return {
            change,
            code,
            options: {
              theme: 'xq-light'
            }
        }
        
    },
})
</script>
<style lang="scss">
#user-code-editor{
    width: 100%;
    pre {
      font-size: 12px;
    }
    ::-webkit-scrollbar {
    width: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: rgb(233, 233, 233);
    }

    ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(20, 20, 20, 0.3);
    }
}
.CodeMirror-scroll {
  margin-bottom: 0px;
  margin-right: -50px;
  padding-bottom: 40vh; //original: 50px
}
.CodeMirror-hscrollbar {
  div {
    height: 1px;
  }
}
.CodeMirror {
  border: 1px solid #eee;
  height: 90vh;//auto;
  
}

</style>
