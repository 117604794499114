<template>
<div class="test-page-panel container">
      <div class="button-row">
        <a-upload
          :file-list="fileList"
          name="file"
          :multiple="false"
          :show-upload-list="false"
          :before-upload="getUploadSvg"
        >
          <a-button>
            <UploadOutlined />
            Upload .svg
          </a-button>
        </a-upload>

        <a-button
          class="follow-button"
          @click="testNextSvg"
        >
          <template #icon>
            <ArrowRightOutlined />
          </template>
          Next
        </a-button>
      </div>

<div>
    <p>
        <b>Testing Result</b>
    </p>
            <p> {{ result }} </p>
    </div>

    <div class="svg-container" v-html="testSvgStr">
        </div>

        <div class="canvas-container">
         </div>
         </div>
</template>
<script lang="ts">
import { defineComponent, watch, ref, reactive, onMounted } from 'vue'
import { filenames } from '../assets/tmp/casedir'
import { ChartDetector, SVGChecklist } from '../assets/vis-testing/detect'
import { message } from 'ant-design-vue'
import { ArrowRightOutlined, UploadOutlined } from '@ant-design/icons-vue'
import * as d3Fetch from 'd3-fetch'
import * as d3Select from 'd3-selection'
export default defineComponent({
    components: {
        ArrowRightOutlined,
        UploadOutlined
    },
    setup() {
        const testSvgStr = ref("")
        const result = ref(({} as SVGChecklist))
        watch(testSvgStr, () => {
            const ele = d3Select.select('.svg-container').select('svg').node() as unknown as SVGElement
            const detector = new ChartDetector(testSvgStr.value, ele, true, true, true)
            detector.runTest().then(v => {
              result.value = v
            })
        }, {
          immediate: false,
          flush: 'post'
        })

        const idx = ref(-1)
        watch(idx, () => {
            const filename = filenames[idx.value]
            d3Fetch.text(filename).then(svgStr => {
                testSvgStr.value = svgStr
            })
        })

        const testNextSvg = () => {
          idx.value = (idx.value + 1) % filenames.length
        }
        
        const fileList = reactive([])
        const getUploadSvg = (file: File) => {
            console.log(file.type, file.type.substring(0, 9))
            const isSvg = file.type.substring(0, 9) === 'image/svg'
            if(!isSvg) {
                message.error('Please upload an svg')
                return
            }
            const reader = new FileReader()
            reader.addEventListener("load", () => {
                testSvgStr.value = reader.result as string
                }, false)
            if (file) reader.readAsText(file)
            
        }
        onMounted(() => {
          testNextSvg()
        })
        return {
            testSvgStr,
            testNextSvg,
            fileList,
            getUploadSvg,
            result
        }
    },
})
</script>
<style lang="scss" scoped>
.test-page-panel {
    .button-row {
        text-align: center;
        display: block;
    }
    .svg-conatiner {
        display: block;
    }
}
</style>
