import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "config-code container" }
const _hoisted_2 = { class: "button-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadOutlined = _resolveComponent("UploadOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_BulbOutlined = _resolveComponent("BulbOutlined")!
  const _component_UploadCases = _resolveComponent("UploadCases")!
  const _component_CopyOutlined = _resolveComponent("CopyOutlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_MonacoCodeEditor = _resolveComponent("MonacoCodeEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_upload, {
        "file-list": _ctx.fileList,
        name: "file",
        multiple: false,
        action: _ctx.backendCodeHandlerUrl,
        "show-upload-list": false,
        "before-upload": _ctx.beforeUploadCode,
        onChange: _ctx.handleUploadCodeFileChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, null, {
            default: _withCtx(() => [
              _createVNode(_component_UploadOutlined),
              _createTextVNode(" script.js ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["file-list", "action", "before-upload", "onChange"]),
      _createVNode(_component_a_button, {
        class: "follow-button",
        onClick: _cache[0] || (_cache[0] = () => _ctx.popOutCases())
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_BulbOutlined)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" Cases ")
        ]),
        _: 1
      }),
      (_ctx.visible)
        ? (_openBlock(), _createBlock(_component_UploadCases, {
            key: 0,
            visible: _ctx.visible,
            "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event))
          }, null, 8, ["visible"]))
        : _createCommentVNode("", true),
      false
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 1,
            class: "follow-button",
            onClick: _cache[2] || (_cache[2] = () => _ctx.pasteFromClipBoard())
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_CopyOutlined)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" Paste ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_select, {
        class: "follow-button",
        "default-value": "7.0.0",
        style: {"width":"100px"},
        onChange: _ctx.updateVersion
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versionList, (v) => {
            return (_openBlock(), _createBlock(_component_a_select_option, { key: v }, {
              default: _withCtx(() => [
                _createTextVNode(" d3-" + _toDisplayString(v), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["onChange"])
    ]),
    _createElementVNode("div", {
      class: "code-panel",
      onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.globalCodeEditorUpdate && _ctx.globalCodeEditorUpdate(...args)))
    }, [
      _createVNode(_component_MonacoCodeEditor, {
        rawCode: _ctx.editingCode,
        onUpdate: _ctx.handleCodeEditorUpdate
      }, null, 8, ["rawCode", "onUpdate"])
    ], 32)
  ]))
}