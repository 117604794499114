<template>
    <div id="user-code-editor">
        <vue-monaco-editor
        v-model:value="code"
        theme="vs-light"
        :options="options"
        @mount="handleMount"
        @update:value="change"
        language="javascript"
        width="100%"
        height="800px"
      />
      <!-- <MonacoEditor
      v-model:modelValue="code"
      language="javascript"
      theme="vs-light"
      :options="options"
      width="100%"
     height="800px"
     @editorMounted="handleMount"
      /> -->
        </div>
        
    </template>
    <script lang="ts">
    import { defineComponent,  ref, watch, shallowRef, onMounted, PropType } from 'vue'
    import VueMonacoEditor from '@guolao/vue-monaco-editor'
    import  MonacoEditor  from '@/components/ui/MonacoEditor.vue'
    import { Suggestion, key } from '@/store'
    import { useStore } from 'vuex'
    import * as monaco from 'monaco-editor' 


    // https://github.com/microsoft/monaco-editor/issues/1621
   
    export default defineComponent({
        name: 'MonacoCodeEditor',
        components: {
            VueMonacoEditor
        },
        props: {
            rawCode: {
                type: String,
                default: ''
            },
        },
        emit: ['update'],
        setup(props, context) {
            const code = ref(props.rawCode)
            
            watch(props, () => {
              code.value = props.rawCode
            })

            const change = function(e: Event):void {
                context.emit('update', e)
                console.log(editorRef.value, 'check editor instance')
            }
            const editorRef = shallowRef()
            const monacoRef = shallowRef()
            const handleMount = (editor: any, m: any) => {
                editorRef.value = editor
                monacoRef.value = m
                console.log('mounted editor', editor, m)
            }
            const store = useStore(key)
            watch(() => store.state.suggestionFlag, () => {
                if (!editorRef.value) return
                const suggestions = store.state.suggestions
                const editor = monacoRef.value.editor.getEditors()[0]
                editor.deltaDecorations(editor.getModel().getAllDecorations().map((d: any) => d.id), [])

                const decorations = suggestions.map(suggestion => ({
                    range: new monaco.Range(suggestion.line, 1, suggestion.line, 1),
                    options: {
                        isWholeLine: true,
                        className: 'suggestion-decoration',
                        hoverMessage: { value: suggestion.message },
                    }
                }))

                editor.deltaDecorations([], decorations)
                    

            })
            return {
                change,
                handleMount,
                code,
                options: {
                    readOnly: false,
                    minimap: {enabled: true},
                    theme: 'hc-light',
                    glyphMargin: false,
                    lineNumbers: "on",
                    wordWrap: "on",
                    lineNumbersMinChars: 4,
                } as monaco.editor.IStandaloneEditorConstructionOptions
            }
            
        },
    })
    </script>
    <style lang="scss">
    #user-code-editor{
        width: 100%;
        min-height: 800px;
        pre {
          font-size: 12px;
        }
        ::-webkit-scrollbar {
        width: 5px;
        }
    
        ::-webkit-scrollbar-track {
            background-color: rgb(233, 233, 233);
        }
    
        ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(20, 20, 20, 0.3);
        }



    .monaco-editor.vs .folded-background {
    background-color: red !important;
    }

    .monaco-editor.vs .margin-view-overlays .folding {
        color: red !important;
    }
    }

    .monaco-editor {
        .suggestion-decoration {
            text-decoration: underline 1px red;
            background: rgb(255, 215, 215);
        }
    }
    </style>
    