<template>
    <div class="test-result-panel container">
        <div v-if="isRunning" class="progress-bar-div">
            <div class="progress-wrapper">
            <a-progress :percent="percent" >
          <template #format>
        <span>{{ `${progress} / ${totalRun}` }}</span>
      </template>

    </a-progress>
            </div>
        </div>
        <Statistics v-if="hasData" :table="table" />
        <ReportTable v-if="hasData" :table="table" @preview="handlePreview"/>
        
        <!-- <a-modal class="previwe-modal" v-model:visible="showModal" title="Preview" @ok="hideModal" :width="1200">
            <div class="previewSvgContainer" :style="{height: svgHeight}">
                <Preview :entry="focusedRecord" @focus="getFocusElement"/>
            </div>
        </a-modal> -->
    <a-card  id="preview-card" v-if="showModal" size="small" title="Detail" :style="{ marginTop: '16px', height: 'fit-content' }">
      <template #extra>
        <CloseOutlined @Click="closeDetailView"/>
      </template>
            <div class="previewContainer">
                <Preview :entry="focusedRecord" @focus="getFocusElement"/>
            </div>
    </a-card>
        
    <div id="test-svg-zone" :style="{visibility: isRunning? 'visible': 'hidden', height: '98vh'}">
       
    </div>
    <div class="canvas-container"></div>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { ReportType, ReportElementType } from '../assets/vis-testing/exec'
import { key } from '../store'
import  ReportTable  from './report/ReportTable.vue'
import Preview from './report/Preview.vue'
import Statistics from './report/Statistics.vue'
import { TableDataType, UITableDatum } from '../assets/ui-config'
import {
    CloseOutlined
} from '@ant-design/icons-vue'
import * as d3 from 'd3'
export default defineComponent({
    name: "Result",
    components: {
        ReportTable,
        Preview,
        Statistics,
        CloseOutlined
    },
    setup() {
        const hasData = ref(false)
        const store = useStore(key)
        const result = ref({
            interactionArray:[],
            datasetArray:[],
            dataConfigArray: [],
            deviceArray:[],
            results:[]
        } as ReportType)
        const table = ref([] as TableDataType[])
        const showModal = ref(false)
        const modalVisibility = ref('hidden')
        const focusedRecord = ref() 
        watch (() => store.state.flag, () => {
            const ele = d3.select('.test_svg').html('').node() as SVGElement
            if(ele)
                ele.removeAttribute('style')
            d3.selectAll('.customStyle').remove()
            hasData.value = false

        })
        watch(() => store.state.isRunning, () => {
            hasData.value = false
        }, {
            deep: true
        })
        watch(() => store.state.testResult, (val) => {
            if(val.results.length == 0) return
            table.value = val.results.map((v, idx) => new UITableDatum(idx.toString(),  v, val))
            hasData.value = true
        }, {
            deep: true
        })

        const isRunning =  computed(() => store.state.isRunning) //ref(false)
        const percent = computed(() => store.state.progress/ store.state.totalRun * 100)
        const progress =  computed(() => store.state.progress)
        const totalRun =  computed(() => store.state.totalRun)
        const closeDetailView = () => {
            showModal.value = false
        }  
        const svgHeight = ref('800px')

        const expand = function (this: any, idx: number) {
             showModal.value = true
        }

        const hideModal = () => {
            showModal.value = false
        }
    return {
        result,
        percent,
        isRunning,
        progress,
        totalRun,
        expand,
        hideModal,
        focusedRecord,
        svgHeight,
        showModal,
        hasData,
        table,
        modalVisibility,
        closeDetailView
    }
  },
  methods: {
      handlePreview (res: ReportElementType) {
          this.focusedRecord = res
          this.showModal = true
      },
      getFocusElement (index:number) {
          // to implement
          console.log(index)
      }
  }
})
</script>
<style lang="scss">
.test-result-panel {
    position: relative;
}
.preview-static-svg-container {
    position: relative;
    svg {
        position: absolute;
        x: 0px;
        y: 0px;
    }
}
.previewContainer {
    padding-left: 12px;
    padding-right: 12px;
}
.test-result-panel {
    padding: 0 0 0 16px;
}
.progress-bar-div {
    width: 80%;
    text-align: center;
    // height: 95vh;
    vertical-align: middle;
    position: relative;
    margin-bottom: 5vh;
 }
.test-svg-zone {
    text-align: center;
    width: 100%;
    svg {
        pointer-events: none;
    }
}

.ant-card-actions {
    li {
        margin: 0 !important;
    }
}

.ant-card-body {
    padding: 5px !important;
    }
.ant-tag {
    margin-right: 3px !important;
}
.ant-image-preview-wrap {
    background: white;
    opacity: 0.9;
}
.ant-image-preview-operations {
    color: black !important;
}

#preview-card {
    position: absolute;
    top: 0px;
    left: 10px;
    z-index: 9999;
    width: 100%;
}

</style>
