<template>
  <div class="config-code container">
      <div class="button-row">
        <a-upload
          :file-list="fileList"
          name="file"
          :multiple="false"
          :action="backendCodeHandlerUrl"
          :show-upload-list="false"
          :before-upload="beforeUploadCode"
          @change="handleUploadCodeFileChange"
        >
          <a-button>
            <UploadOutlined />
            script.js
          </a-button>
        </a-upload>

        <a-button
          class="follow-button"
          @click="() => popOutCases()"
        >
          <template #icon>
            <BulbOutlined />
          </template>
          Cases
        </a-button>
        <UploadCases v-if="visible" v-model:visible="visible" />

         <a-button v-if="false"
          class="follow-button"
          @click="() => pasteFromClipBoard()"
        >
          <template #icon>
            <CopyOutlined />
          </template>
          Paste
        </a-button>

        <a-select class="follow-button" default-value="7.0.0" style="width: 100px" @change="updateVersion">
          <a-select-option v-for="v in versionList" :key="v">
            d3-{{v}}
          </a-select-option>
        </a-select>
      </div>
      <div class="code-panel"  @mouseleave="globalCodeEditorUpdate">
        <MonacoCodeEditor :rawCode="editingCode" @update="handleCodeEditorUpdate" />
      </div>

  </div>
</template>
<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, reactive, ref, watch } from "vue"
import { UploadOutlined, CopyOutlined, BulbOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { key } from '../store'
import { initCode } from '../assets/tmp/init'
import { message } from 'ant-design-vue'
import CodeEditor from '../components/ui/CodeEditor.vue'
import MonacoCodeEditor from '../components/ui/MonacoCodeEditor.vue'
import UploadCases from './UploadCases.vue'
import "codemirror/theme/duotone-light.css"
export default defineComponent({
  name: "CodeConfig",
  components: {
       UploadOutlined,
       CopyOutlined,
       //CodeEditor,
       MonacoCodeEditor,
       BulbOutlined,
       UploadCases,
  },
  setup(props, context) {
      const store = useStore(key)
      const fileList = reactive([])
      const loading = ref(false)
      const hasFile = ref(false)
      const code = ref(initCode)
      const editingCode = ref(initCode)
      const isEditing = ref(false)
      const versionList = ref([
        '7.0.0',
        '3.0.0'
      ])
      watch(() => store.state.flag, () => {
          code.value = store.state.code
          editingCode.value = store.state.updateCode
          nextTick(() => isEditing.value = false)
      })
      const backendCodeHandlerUrl = computed(() => store.state.backendUrl + 'uploadForm')
      const beforeUploadCode = (file:File) => {
            const isJsScript = file.type === 'text/javascript'
            if(!isJsScript) message.error('You can only upload .js file!')
            const isLt5M = file.size >> 20 < 5
            if (!isLt5M) message.error('The file must smaller than 5 Mb!')
            if(isLt5M && isJsScript) {
              const reader = new FileReader()
              reader.addEventListener("load", () => {
                  code.value = reader.result as string
                  store.commit('updateCode', code.value)
                  editingCode.value = store.state.updateCode
                  }, false)
                  if (file) {
                      reader.readAsText(file)
                  }
            }
            return isJsScript && isLt5M
      }
      const handleUploadCodeFileChange = (e:any) => {
        if (e.file.status === 'uploading') {
            loading.value = true
            return
        }
        if (e.file.status === 'done') {
            loading.value = false
            hasFile.value = true
            return
        }
        if (e.file.status === 'error') {
            loading.value = false
            message.error('Upload error. Please try again.')
        }
    }
      const pasteFromClipBoard = () => {
          navigator.clipboard.readText().then(text => {
            editingCode.value = text
          })
      }

      const visible = ref<boolean>(false);
      const popOutCases = () => {
        visible.value = true
      }

      const handleCodeEditorUpdate = (editorValue: string) => {
          editingCode.value = editorValue
          isEditing.value = true    
      }

      const globalCodeEditorUpdate = (e: Event) => {
        if(isEditing.value) {
          store.commit('updateCode', editingCode.value)
          code.value = store.state.updateCode
          editingCode.value = store.state.code
          console.log('updated code')
        }
        isEditing.value = false
      }
      const updateVersion = (version: string) => {
          store.commit('updateVersion', version)
      }


      onMounted(() => {
          code.value = '' //initCode,
          editingCode.value = '' //initCode
          store.commit('updateCode', code.value)
      })

      return {
          fileList,
          loading,
          code,
          editingCode,
          versionList,
          hasFile,
          backendCodeHandlerUrl,
          visible,
          popOutCases,
          beforeUploadCode,
          pasteFromClipBoard,
          handleCodeEditorUpdate,
          handleUploadCodeFileChange,
          globalCodeEditorUpdate,
          updateVersion,
      }
  },
})
</script>
<style lang="scss">
.button-row {
  padding-left: 16px;
  margin-bottom: 1vh;
}
.follow-button {
    margin-left: 10px !important;
}
.code-panel {
    max-width: 100%;
    // overflow-y: scroll;
    // overflow-x: hidden;
    pre {
        font-size: 10pt;
    }
}
</style>
