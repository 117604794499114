import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { store, key }  from './store'
import router from './router'
import Antd from 'ant-design-vue'
import JsonViewer from "vue3-json-viewer"
import 'ant-design-vue/dist/antd.css'
import 'splitpanes/dist/splitpanes.css'
import "vue3-json-viewer/dist/index.css";
import "codemirror/theme/base16-light.css"
import "codemirror/theme/xq-light.css"
import "./assets/vis-testing/utils/inject" // update element prototype to record interaction
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'



// loader.config({monaco})
createApp(App)
.use(store, key)
.use(router)
.use(Antd)
.use(JsonViewer)
.use(VueMonacoEditorPlugin, {
    paths: {
        vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
    }
})
.mount('#app')

