import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "user-code-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VCodeMirror = _resolveComponent("VCodeMirror")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VCodeMirror, {
      value: _ctx.code,
      theme: "white",
      options: _ctx.options,
      "onUpdate:value": _ctx.change
    }, null, 8, ["value", "options", "onUpdate:value"])
  ]))
}