import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "user-code-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_monaco_editor = _resolveComponent("vue-monaco-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vue_monaco_editor, {
      value: _ctx.code,
      "onUpdate:value": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
        _ctx.change
      ],
      theme: "vs-light",
      options: _ctx.options,
      onMount: _ctx.handleMount,
      language: "javascript",
      width: "100%",
      height: "800px"
    }, null, 8, ["value", "options", "onMount", "onUpdate:value"])
  ]))
}