<template>
   <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsedNavigation" collapsible theme="light">
      <div class="logo" />
      <!-- <a-menu v-model:selectedKeys="selectedKeysForNavigation" theme="dark" mode="inline">
        <a-menu-item key="1">
           <router-link to="/testpage">
              <SettingOutlined />
              <span>Test</span>
              </router-link>
        </a-menu-item>
        <a-menu-item key="3">
          <router-link to="/browser">
              <PieChartOutlined />
              <span>Browser</span>
              </router-link>
        </a-menu-item>
    
        <a-menu-item key="6">
          <router-link to="/about">
         <question-outlined />
              <span>About</span>
              </router-link>
        </a-menu-item>
        <a-menu-item key="9">
          <file-outlined />
          <span>File</span>
        </a-menu-item>
      </a-menu> -->
      <a-menu v-model:selectedKeys="selectedKeysForNavigation" theme="light" mode="inline">
        <a-menu-item key="1">
          <router-link to="/">
          <home-outlined />&nbsp;
          <span>Home</span>
          </router-link>
        </a-menu-item>
         <a-menu-item key="2">
          <router-link to="/tool">
          <CodeOutlined />&nbsp;
          <span>Tool</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="3">
          <router-link to="/about">
         <question-outlined />&nbsp;
              <span>About</span>
              </router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
            <!-- <a-layout-header style="background: #fff; padding: 0" /> -->
      <a-layout-content style="margin: 0 16px">
         <!-- <a-row>
            <a-col :span="12"> <CodeConfig/> <DataConfig/></a-col>
            <a-col :span="12"> <Result/>  </a-col>
          </a-row> -->

        <splitpanes style="height: 95vh">

          <pane size="40">
            <splitpanes horizontal>
              <pane><CodeConfig/></pane>
              <pane><DataConfig/></pane>
            </splitpanes>
          </pane>
          <pane size="60">
            <splitpanes horizontal>
            <pane><Result/></pane>
             </splitpanes>
            </pane>
        </splitpanes>
            
          </a-layout-content>
      <a-layout-footer style="text-align: center">
        Vizarre ©2024
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import CodeConfig from '../components/CodeConfig.vue'
import DataConfig from '../components/DataConfig.vue'
import Result from '../components/Result.vue'
import { Splitpanes, Pane } from 'splitpanes'
import {
  CodeOutlined,
  QuestionOutlined,
  HomeOutlined
} from '@ant-design/icons-vue'
export default defineComponent({
  name: 'Home',
  components: {
    CodeConfig,
    DataConfig,
    Result,
    CodeOutlined,
    QuestionOutlined,
    HomeOutlined,
    Splitpanes,
    Pane 
  },
  setup() {
    const selectedKeysForNavigation = reactive(['2'])
    const collapsedNavigation = ref(true)
    return {
      selectedKeysForNavigation,
      collapsedNavigation
    }
  }
});
</script>
<style lang="scss">
.logo {
  height: 32px;
  margin: 16px;
  background-color: #EFF2F5;
}
.ant-layout-content {
  margin: 0 0 0 16px !important;
}

.site-layout .site-layout-background {
  background: #fff;
}
.site-layout .site-layout-background {
  background: #141414;
}
.ant-layout-footer {
  padding: 5px 5px !important;
}
.splitpanes__pane {
  display: flex;
  justify-content: center;
  align-items: center;

}
.splitpanes__splitter {
  background-color: #e9e9e9 !important;
}

.splitpanes--vertical>.splitpanes__splitter {
  min-width: .3vw !important;
}
.splitpanes--horizontal>.splitpanes__splitter {
  min-height: .3vw !important;
}
.splitpanes__pane {
  .container {
    width: 100%;
    height: 100%;
  }
  overflow-y: scroll !important;
  overflow-x: hidden;
}
</style>
