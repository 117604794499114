<template>
<a-list-item>
<div class="card-container">
     <a-card @click="clickOnInstance">
            <a-tag v-for="(error, eid) in record.result.filter(v => v.value != 0)" :color="error.value?'red':'green'" :key="`tag_${eid}`">
                    {{ error.error.replaceAll('_', ' ')  }}
            </a-tag>
        <template #cover>
            <div class="previewImageWrapper"> 
                <a-image
                width="100%" 
                class="previewImage"
                :alt="`Thumbnail of test result No. ${index}`"
                :src="record.thumbnail"
                fallback="./img/fallback.png"
                
            > </a-image>
        </div>
           
        </template>
        
     </a-card>
</div>
</a-list-item>
</template>
<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    name: 'Card',
    props: {
        record: Object,
        index: Number
    },
    emits: ['focus'],
    setup(props, context) {
        
        return {

        }
    },
    methods: {
        clickOnInstance() {
            this.$emit('focus', this.index)
        }
    }

})
</script>
<style lang="scss">
.previewImageWrapper {
    width: 100%;
    aspect-ratio: 1;
    vertical-align: middle;
    position: relative;
    margin: 0 auto;
    .ant-image {
        position: absolute; 
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%); 
    }

}



</style>