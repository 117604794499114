import * as randomSeed from 'random-seed'
/**
 * Commonly used utility functions
 */
/**
 * Change the order of elements inside an array.
 * @param array 
 * @returns 
 */
function shuffle<T>(array: T[]):T[] {
    let currentIndex = array.length,  randomIndex
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]]
    }
    return array
  }

  function shuffleWithSeed<T>(array: T[], seed: string):T[] {
    const gen = randomSeed.create(seed)
    let currentIndex = array.length,  randomIndex
    while (currentIndex != 0) {
      randomIndex = Math.floor(gen.random() * currentIndex)
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]]
    }
    return array
  }

/**
 * @param bottom smallest int
 * @param top largesr int (included)
 * @returns a random integer generator
 */ 
function randintGenerator(bottom:number, top: number): ()=>number {
    return function() {
        return Math.floor( Math.random() * ( 1 + top - bottom ) ) + bottom;
    }
}

function randintGeneratorWithSeed(bottom:number, top: number, seed: string): ()=>number {
  const gen = randomSeed.create(seed)
  return function() {
      return Math.floor( gen.random() * ( 1 + top - bottom ) ) + bottom;
  }
}

function getRandomAlphabet(n=1):string {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for ( let i = 0; i < n; i++ ) 
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
   return result
}

/**
 * Essential interaction type to consider.
 */
const interactionList = [
  'onclick',
  'ondlbclick',
  'ondrag',
  'ondragenter',
  'ondragleave',
  'ondragover',
  'ondragstart',
  'onfocus',
  'onkeyup',
  'onmouseover',
  'onmouseout',
  'onmousewheel',
  'onmouseup',
  'onpointerenter',
  'onpointerleave',
  'onpointerout',
  'onpointerover',
  'onpointermove',
  'onscroll',
]

export {
    shuffle,
    shuffleWithSeed,
    randintGenerator,
    randintGeneratorWithSeed,
    interactionList,
    getRandomAlphabet,
}