<template>
  <div class="container" id="config-panel">
    <div class="card-container">
      <a-layout>  
        <a-layout-content>    
          <a-tabs v-model:activeKey="activeKeyForDataConfigTab" type="card">
      <a-tab-pane key="5" tab="Config">
          <div class="button-row">
           <a-upload
                    :file-list="configFileList"                             
                    name="config-file"
                    class="follow-button"
                    :multiple="false"
                    :action="backendConfigHandlerUrl"
                    :show-upload-list="false"
                    :before-upload="beforeUploadConfig"
                    @change="handleUploadDatasetFileChange">
                        <a-button>
                        <UploadOutlined/>
                        config.json
                        </a-button>
                </a-upload>

            <a-button class="follow-button" @click="exportConfig">
              <DownloadOutlined/>
              export
              </a-button>
            </div>
            <div class="code-panel" @mouseleave="updateConfigRaw">
            <CodeEditor :rawCode="JSON.stringify(getVisibleConfig(config), null, '  ')" @update="handleConfigEditorUpdate"/>
          </div>
      </a-tab-pane> 
        <a-tab-pane key="6" tab="Style">
          <div class="button-row">
            <a-upload
              :file-list="styleFileList"                             
              name="config-style"
              class="follow-button"
              :multiple="false"
              :action="backendConfigHandlerUrl"
              :show-upload-list="false"
              :before-upload="beforeUploadStyle"
              @change="handleUploadDatasetFileChange">
                  <a-button>
                  <UploadOutlined/>
                  style.css
                  </a-button>
             </a-upload>
           </div>
                      <div class="code-panel">
            <CodeEditor :rawCode="style" @update="handleStyleEditorUpdate"/>
          </div>
      </a-tab-pane>                               
      <a-tab-pane key="1" tab="Dataset">
            <div class="button-row">
                <a-upload
                    :file-list="datasetFileList"
                    name="file"
                    :multiple="false"
                    :action="backendDatasetHandlerUrl"
                    :show-upload-list="false"
                    :before-upload="beforeUploadDataset"
                    @change="handleUploadDatasetFileChange">
                        <a-button>
                        <UploadOutlined/>
                        dataset.csv
                        </a-button>
                </a-upload>
              </div>
      
        <div v-if="hasData">
        <div>
          <span><b>Dataset Size: </b></span>
          <span>{{ dataset.length }} </span>
        </div>
            <a-table :columns="dataColumns" 
               :data-source="dataAttrTable" 
               size="small" 
               :pagination="{ hideOnSinglePage: false, size: `small`, pageSize: 5}"
               :scroll="{ y: tableHeight }" 
            >
               <template #distributionCol="{ record }">
                  <div :style="{ width: '100%', height: '25px'}">
                    <svg class="dist-svg" width="100%" height="100%"  preserveAspectRatio="xMidYMid meet">
                      <rect v-for="(bin, bidx) in record.bins" :key="bidx" :x="bidx * 8"  width="8" :y="20-bin/Math.max(...record.bins)*20" :height="bin/Math.max(...record.bins)*20">
                      </rect>
                    </svg>
                  </div>
               </template>
               <template #actionCol="{ record }">
                  <template v-for="(val, index) in record.insertValues" :key="val">
                    <a-tag :closable="true" @close="handleCloseInput(record.key, index)">
                      {{ val }}
                    </a-tag>
                  </template>
                  <a-input
                    v-if="inputVisible[record.key]"
                    ref="input"
                    :type="record.type=='string'?'text':'number'"
                    size="small"
                    :style="{ width: '78px' }"
                    v-model:value="inputValue[record.key]"
                    @blur="handleInputStart(record.key)"
                    @keyup.enter="handleInputConfirm(record.key)"
                  />
                  <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInputValue(record.key)">
                    <PlusOutlined/>
                  </a-tag>
               </template>
            </a-table>
        <div v-if="dataset.length!=0">
        <div class="form-wrapper"> 
          <span> Scale:&nbsp; </span>
          <template v-for="(val, index) in config.dataConfig.scale" :key="val">
                  <a-tag :closable="true" @close="handleCloseInput('scale', index)">
                    {{ val.dimension }}: {{ val.value }}
                  </a-tag>
          </template>
          <a-form layout="inline" v-if="dataFormVisible.scale">
                <a-form-item label="dimension" name="dimension">
                  <a-select size="small" v-model:value="dataFormInput.scale.dimension" placeholder="dimension" style="min-width: 120px">
                    <template v-for="(dim, didx) in Object.keys(dataset[0])" :key="`scale_${didx}`">
                      <a-select-option :value="dim">{{ dim }}</a-select-option>
                    </template>
                  </a-select>
              </a-form-item>
              <a-form-item label="factor" name="factor">
                 <a-input-number size="small" v-model:value="dataFormInput.scale.value" :min="0" placeholder="ratio" style="width: 60px" />
              </a-form-item>
              <a-form-item>
                 <a-button size="small" @click="handleInputConfirm('scale')"> OK </a-button>
                   </a-form-item>            
          </a-form>
          <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInputValue('scale')">
                    <PlusOutlined/>
          </a-tag>

        </div>

           <div class="form-wrapper"> 
          <span> Perturb:&nbsp; </span>
          <template v-for="(val, index) in config.dataConfig.perturb" :key="val">
                  <a-tag :closable="true" @close="handleCloseInput('perturb', index)">
                    {{ val.dimension }}: {{ val.value }}
                  </a-tag>
          </template>
           <a-form layout="inline" v-if="dataFormVisible.perturb">
             <a-form-item label="dimension" name="dimension">
              <a-select size="small" v-model:value="dataFormInput.perturb.dimension" placeholder="dimension" style="min-width: 120px">
                    <template v-for="(dim, didx) in Object.keys(dataset[0])" :key="`perturb_${didx}`">
                      <a-select-option :value="dim">{{ dim }}</a-select-option>
                    </template>
                  </a-select>
              </a-form-item>
              <a-form-item label="extent" name="perturb">
                 <a-input-number size="small" v-model:value="dataFormInput.perturb.value" :step="0.1" :min="0" placeholder="extent" style="width: 60px" />
              </a-form-item>
              <a-form-item>
                 <a-button size="small" @click="handleInputConfirm('perturb')"> OK </a-button>
                   </a-form-item> 
          </a-form>
          <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInputValue('perturb')">
                    <PlusOutlined/>
          </a-tag>
          
        </div>

        <div class="form-wrapper"> 
          <span> Sample:&nbsp; </span>
          <template v-for="(val, index) in config.dataConfig.sample" :key="val">
                  <a-tag :closable="true" @close="handleCloseInput('sample', index)">
                    ×{{val}}
                  </a-tag>
          </template>
           <a-form layout="inline" v-if="dataFormVisible.sample">
              <a-form-item label="ratio" name="ratio">
                 <a-input-number size="small" v-model:value="dataFormInput.sample" :min="0" placeholder="extent" style="width: 60px" />
              </a-form-item>
              <a-form-item>
                 <a-button size="small" @click="handleInputConfirm('sample')"> OK </a-button>
              </a-form-item> 
          </a-form>
          <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInputValue('sample')">
             <PlusOutlined/>
          </a-tag>
          
        </div>

        <div class="form-wrapper"> 
          <span> Shuffle:&nbsp; </span>
           <a-input-number size="small" v-model:value="config.dataConfig.shuffle" :min="0" placeholder="#times" />
           <span>&nbsp; times</span>
        </div>

        <div class="form-wrapper"> 
          <span> Use Original Data:&nbsp; </span>
           <a-switch size="small" v-model:checked="config.dataConfig.static" />
        </div>
  
        </div>
        </div>
        
      </a-tab-pane>
      <a-tab-pane key="2" tab="Interaction">
        <a-button v-if="false"  @click="performCodeAnalysis">
            <InfoCircleOutlined />
            Check Out
        </a-button>
        <div class="available-events-div">
          <span>Available Events in the Code: &nbsp;&nbsp;</span> <span v-if="!hasInteraction">None</span>
          <a-tag v-for="(binding, bidx) in bindings.filter(v=>v.classNames.length!=0)" :key="`_binding_${bidx}`">
            <!-- {{ `${binding.event} (${binding.classNames.length})` }} -->
            {{ `${binding.event}` }} 
          </a-tag>
          <div v-if="hasInteraction">
           <span>Current Configurations: </span>
          <span v-if="interactConfigs.length==0"> &nbsp; None </span>
          <span v-else> {{ interactConfigs.length }}&nbsp; in total</span>
          </div>
          </div>
        
        <div v-if="hasInteraction">
          <div class="section-wrapper">
            <a-collapse  v-model:activeKey="interactActiveKey" expand-icon-position="left">
              <a-collapse-panel v-for="(interactConfig, interactIdx) in interactConfigs" :key="`${interactIdx}`" :header="`${interactConfig.tag}`">
                <!-- {{ JSON.stringify(interactConfig)}} -->
                <div>
                  <template v-if="interactConfig.sequence.length > 1">
                  <span> Keep: &nbsp; </span>
                  <span v-if="interactConfig.keep "> keep triggering the same element </span>
                  <span v-else> randomly trigger elements of the group </span>
                  </template>
                  </div>
                  <span> Times: {{interactConfig.times}} </span> 
                    <div>
                      <span> Sequence: &nbsp; </span>
                <template v-for="(item, i) in interactConfig.sequence" :key="`seq_${interactIdx}_${i}`">
                    <span> {{ `${i!=0?' → ':''}` }} </span>
                    <a-tag> {{ `  ${item.event} `  }} </a-tag>
                    <span> {{ ` (${item.element || 'random element'})` }} </span>
                  </template>
                  </div>
                  <template #extra><DeleteOutlined @click="handleDeleteInteractionConfig(interactIdx,interactConfig)" /></template>
                  </a-collapse-panel>
                
            </a-collapse>
          </div>
            <template v-if="!needAddInteraction" >
              <a-button class="full-width" @click="addInteractionConfig">
                <PlusOutlined />
                 Add Interaction Config
              </a-button>
            </template>
            <template v-else-if="true">
              <div class="form-wrapper">
                 <!-- <a-divider orientation="middle">  <PlusOutlined /> </a-divider> -->
                 <a-form ref="interactionFormRef"
                    :model="interactionForm"
                  >
                    <a-form-item  ref="label" label="Label" name="label">
                      <a-input size="small" v-model:value="interactionForm.tag" placeholder="alias" />
                    </a-form-item>
                    <a-row :gutter="8">
                    <a-col :span="12">
                      <a-form-item ref="times" label="Times" name="times">
                        <a-input-number size="small" v-model:value="interactionForm.times" :min="1" placeholder="#simulation" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item ref="keep" label="Keep" name="keep">
                          <a-switch  size="small" v-model:checked="interactionForm.keep" />
                        </a-form-item>
                    </a-col>
                    </a-row>
                    <a-row :gutter="8">
                    <template v-for="(seq, index) in interactionForm.sequence" :key="`seq_${index}`">
                      <a-col :span="11">
                      <a-form-item
                        :label="`#${index+1} Event`"
                        :name="`event_${index}`"
                      >
                      <a-select size="small" v-model:value="seq.event" placeholder="event">
                        <template v-for="(binding, bidx) in bindings.filter(v=>v.classNames.length!=0)" :key="`select_${bidx}_${index}`">
                          <a-select-option :value="binding.event"> {{ binding.event }} </a-select-option>
                        </template>
                      </a-select>
                      </a-form-item>
                      </a-col>
                      <a-col :span="11">
                        <a-form-item  label="Element">
                        <a-input
                          size="small"
                          :name="`element_${index}`"
                          v-model:value="seq.element"
                          placeholder="selector"
                        />
                      </a-form-item>
                    </a-col>
                     <a-col :span="2">
                        <MinusCircleOutlined
                          v-if="interactionForm.sequence.length > 1"
                          class="dynamic-delete-button"
                          :disabled="interactionForm.sequence.length === 1"
                          @click="removeInteractionSeq(index)"
                        />
                      </a-col>
                    </template>
                    </a-row>
                    <a-form-item>
                      <a-button size="small" type="dashed" @click="addInteractionSeq">
                        <PlusOutlined /> event sequence
                      </a-button>


                    </a-form-item>
                </a-form>
                <div class="center-wrapper">
                  <a-form-item>
                    <a-button size="small"  @click="confirmAddInteraction"> <CheckCircleOutlined /> OK </a-button>
                  </a-form-item>
                </div>
              </div>
            </template>
           </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Device">
          <a-table bordered :data-source="deviceDataSource" :columns="deviceColumns"  size="small" :pagination="{size: 'small', pageSize: 5}">
            <template #[`updateDeviceTag`]="{ text, record }">
               <div class="editable-cell">
                <div v-if="editableDeviceData[record.key] && editableDeviceData[record.key]['label']" class="editable-cell-input-wrapper">
                  <a-input  v-model:value="editableDeviceData[record.key]['label']" @pressEnter="saveDeviceSetting(record.key)" /> &nbsp;
                  <check-outlined class="editable-cell-icon-check" @click="saveDeviceSetting(record.key, 'label')" />
                </div>
                <div v-else class="editable-cell-text-wrapper">
                  {{ text || ' ' }}
                  <edit-outlined class="editable-cell-icon" @click="editDeviceSetting(record.key, 'label')" />
                </div>
                </div>
            </template>
            <template v-for="col in ['width', 'height']" #[col]="{ text, record }" :key="col">
              <div class="editable-cell">
                <div v-if="editableDeviceData[record.key] && editableDeviceData[record.key][col]" class="editable-cell-input-wrapper">
                  <a-input  v-model:value="editableDeviceData[record.key][col]" @pressEnter="saveDeviceSetting(record.key, col)" /> &nbsp;
                  <check-outlined class="editable-cell-icon-check" @click="saveDeviceSetting(record.key, col)" />
                </div>
                <div v-else class="editable-cell-text-wrapper">
                  {{ text || ' ' }}
                  <edit-outlined class="editable-cell-icon" @click="editDeviceSetting(record.key, col)" />
                </div>
              </div>
            </template>
            <template #previewDevice="{ record }">
              <div :style="{ width: '100%', height: '16px'}">
                <svg class="preview-device-svg" width="100%" height="100%" :viewBox="`${-0.1 * record.width} ${-0.1 * record.height} ${record.width * 1.2} ${record.height * 1.2}`" preserveAspectRatio="xMidYMid meet">
                    <rect x="0" y="0" rx="5" ry="5" :width="record.width" :height="record.height"></rect>
                  </svg>
              </div>
            </template>
            <template #deleteDevice="{ record }">
              <a-popconfirm
                v-if="deviceDataSource.length"
                title="Sure to delete?"
                @confirm="onDeleteDeviceSetting(record.key)"
              >
                <a>delete</a>
              </a-popconfirm>
            </template>
             <template #footer>
               <a-row type="flex" align="middle" justify="center">
                  <a-col :span="6">
                     <a-button class="editable-add-btn" @click="handleAddDeviceSetting(640, 360)" ><PlusOutlined/>640 × 360 </a-button>
                  </a-col>
                    <a-col :span="6">
                     <a-button class="editable-add-btn" @click="handleAddDeviceSetting(500,500)"><PlusOutlined/>500 × 500</a-button>
                  </a-col>
                    <a-col :span="6">
                     <a-button class="editable-add-btn" @click="handleAddDeviceSetting(540,405)"><PlusOutlined/>540 × 405 </a-button>
                  </a-col>
                   <a-col :span="6">
                     <a-button class="editable-add-btn" @click="handleAddDeviceSetting(800, 400)"><PlusOutlined/>800 × 400 </a-button>
                  </a-col>
                </a-row>
               <!-- <a-button class="editable-add-btn" @click="handleAddDeviceSetting" style="margin: 0px; width: 100%;"><PlusOutlined/>Add</a-button> -->
             </template>
          </a-table>
      </a-tab-pane>

      <a-tab-pane key="4" tab="Detection">
          <a-table bordered :data-source="detectDataSource" :columns="detectColumns"  size="small" :pagination="false">
              <template #detectCheckList="{ record }">
                <a-checkbox v-if="record._key!='error'" v-model:checked="record.isOn" @change="updateDetectConfig(record)"></a-checkbox>
                <a-checkbox v-else :checked="true" disabled></a-checkbox>
              </template>
              <template #setDetectThresholdValue="{ record }">
                <!-- default threshold has to be larger than 0  -->
                <div v-if="record.threshold" >
                    <span>
                      <a-input v-model:value="record.threshold" @pressEnter="updateDetectConfig(record)" @change="updateDetectConfig(record)" size="small" :style="{ width: '25px' }"></a-input>
                    </span>
                 <span v-html="`&nbsp;&nbsp;${record.hint}`"></span>
                </div>
                <div v-else-if="record._key==`invalidValue`">
                    <template v-for="(val, index) in config.errorDetection.invalidValue.details" :key="val">
                      <a-tag :closable="true" disabled @close="removeListVal('undesired', index)">
                      {{ val }}
                      </a-tag>
                    </template>
                    <a-input
                      v-if="needInputUndesiredValue"
                      ref="input"
                      type="text"
                      size="small"
                      :style="{ width: '78px' }"
                      v-model:value="tmpUndesiredInputVal"
                      @blur="inputStartListVal('undesired')"
                      @keyup.enter="confirmAddListVal('undesired')"
                    />
                    <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="()=>needInputUndesiredValue=true">
                      <PlusOutlined/>
                    </a-tag>

                </div>
              </template>
              <template #highlightColor="{ record }">
              <div :style="{ width: '100%', height: '16px'}">
                <svg class="highlight-color-svg" width="100%" height="100%" :viewBox="`0 0 20 10`" preserveAspectRatio="xMidYMid meet">
                    <rect v-if="'highlightColor' in record" x="0" y="0" width="20" height="10" :style="{ fill: record.highlightColor, opacity: 0.2}"></rect>
                  </svg>
              </div>
            </template>
          </a-table>
      </a-tab-pane>
    </a-tabs>
        </a-layout-content>
    <a-layout-footer :style="{ position: 'sticky'}">
      <a-button @click="runTest" :style="{ marginLeft: 16}">
        <template #icon>
          <RocketOutlined />
        </template> Start Test
      </a-button>
    </a-layout-footer>
  </a-layout>
  </div>
     
    </div>
</template>
<script lang="ts">
import { watch, computed, defineComponent, onMounted, reactive, ref, UnwrapRef, toRefs } from 'vue'
import { MinusCircleOutlined, CheckCircleOutlined, UploadOutlined, RocketOutlined, EditOutlined, CheckOutlined, PlusOutlined, InfoCircleOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons-vue'
import { DeviceSettingType, TestConfigFile, getDataAttrTable, InteractionConfigType, InteractionConfig, BasicDataConfig } from '../assets/vis-testing/config' 
import CodeEditor from './ui/CodeEditor.vue'
import { message } from 'ant-design-vue'
import * as d3Dsv from 'd3-dsv'
import { getVisibleConfig } from '../assets/vis-testing/auto'
import { Runner } from '../assets/vis-testing/exec'
import { key } from '../store'
import { useStore } from 'vuex'
import { Analyzer } from '../assets/vis-testing/analyze'
export default defineComponent({
    name: 'DataConfig',
    components: {
        CheckCircleOutlined,
        UploadOutlined,
        DownloadOutlined,
        RocketOutlined,
        EditOutlined,
        DeleteOutlined,
        CheckOutlined,
        PlusOutlined,
        InfoCircleOutlined,
        MinusCircleOutlined,
        CodeEditor
    },
    setup() {
        const activeKeyForDataConfigTab = ref('1') //5
        const store = useStore(key)
        // dataset configuration
        let datasetFileList = reactive([])
        let dataset = ref([] as Record<string, any>[])
        let configFileList = reactive([])
        let styleFileList = reactive([])
        const config = ref(new TestConfigFile({}))
        const interactConfigs = computed(() => config.value.interactionConfig)
        const interactActiveKey = ref('0')
        const bindings = computed(() => store.state.bindings)
        const hasInteraction = computed(() => bindings.value.filter(v=>v.classNames.length!=0).length != 0)
        let style = ref('')

        watch(() => store.state.flag, () => {
          // when the case combo is loaded
          config.value = store.state.config
          dataset.value = store.state.dataset
          dataAttrTable.value = getDataAttrTable(dataset.value)
          style.value = store.state.style
          updateConfigUI()

        })
        let needUpdateConfig = false
        let updatedConfigRaw = ""
        
        const handleConfigEditorUpdate = function (text: string): void {
          needUpdateConfig = true
          updatedConfigRaw = text
        }
 
        const updateConfigRaw = function ():void {
          if(!needUpdateConfig) return
          try {
            const obj = JSON.parse(updatedConfigRaw)
            updatedConfigRaw = ""
            needUpdateConfig = false
            config.value = new TestConfigFile(obj)
          } catch(e) {
            updatedConfigRaw = JSON.stringify(config.value, null, '  ')
            message.error('syntax error in config.json')
            console.warn('json syntax error', '\n', e)
          }
        }

        const handleStyleEditorUpdate = function (text: string) {
          style.value = text
        }
        const dataAttrTable = ref(getDataAttrTable(dataset.value))
        const tableHeight = computed(() => {
          return 300
        })
        const dataColumns = [{
          title: 'field',
          dataIndex: 'field'
        }, {
          title: 'min',
          dataIndex: 'min'
        }, {
          title: 'max',
          dataIndex: 'max'
        }, {
          title: 'type',
          dataIndex: 'type'
        },{
          title: 'dist',
          slots: { customRender: 'distributionCol'}

        }, {
          title: '+ value',
          slots: { customRender: 'actionCol'}
        }]
        const hasData = computed(() => {
            return dataset.value.length > 0
        })
        
  
        const backendDatasetHandlerUrl = computed(() => {
            return store.state.backendUrl + "uploadData"
        })
       
        const beforeUploadDataset = (file:File) => {
            const format = file.name.substring(file.name.lastIndexOf('.') + 1);
            const isCsv = format == 'csv'
            if(!isCsv) message.error('You can only upload CSV file!')
            const isLt5M = file.size >> 20 < 5
            if (!isLt5M) message.error('The file must smaller than 5 Mb!')
            if(isLt5M && isCsv) {
                const reader = new FileReader()
                reader.addEventListener("load", () => {
                    const csvString = reader.result
                    if(typeof(csvString) != 'string') return
                    dataset.value = d3Dsv.csvParse(csvString, d3Dsv.autoType) as unknown as Record<string, any>[]
                    dataAttrTable.value = getDataAttrTable(dataset.value)
                    dataDimConfigState.inputVisible = new Array(dataAttrTable.value.length).fill(false)
                    dataDimConfigState.inputValue = new Array(dataAttrTable.value.length).fill('')
                    dataDimConfigState.dataFormVisible.scale = false
                    dataDimConfigState.dataFormVisible.perturb = false
                    dataDimConfigState.dataFormVisible.sample = false
                }, false)
                if (file) {
                    reader.readAsText(file)
                }
            }
            return isCsv && isLt5M
        }
        const handleUploadDatasetFileChange = (e:any) => {
            if (e.file.status === 'uploading') {
                console.log('loading the dataset to the backend')
                return
            }
            if (e.file.status === 'done') {
                console.log('has uploaded the dataset to the backend')
                return
            }
            if (e.file.status === 'error') {
                console.log('failed to upload the dataset to the backend')
                message.error('Upload error. Please try again.')
            }
        }

        const backendConfigHandlerUrl = computed(() => {
            return store.state.backendUrl + "uploadConfig"
        })

        const beforeUploadConfig = (file: File) => {
          const format = file.name.substring(file.name.lastIndexOf('.') + 1)
          const isJson = format == 'json'
          if(!isJson) message.error('You can only upload JSON file as test configuration!')
          const isLt5M = file.size >> 20 < 5
          if (!isLt5M) message.error('The file must smaller than 5 Mb!')
          if(isLt5M && isJson) {
            const reader = new FileReader()
            reader.addEventListener("load", () => {
                const jsonString = reader.result
                if(typeof(jsonString) != 'string') return
                const obj = JSON.parse(jsonString) as Partial<TestConfigFile>
                config.value = new TestConfigFile(obj)
                updateConfigUI()
                // !!!!! need type checking

                store.commit('updateConfig', config)
            }, false)
            if (file) {
                reader.readAsText(file)
            }
            return isJson && isLt5M
        }
      }

       const beforeUploadStyle = (file: File) => {
          const format = file.name.substring(file.name.lastIndexOf('.') + 1)
          const isCss = format == 'css'
          if(!isCss) message.error('You can only upload CSS file as test configuration!')
          const isLt5M = file.size >> 20 < 5
          if (!isLt5M) message.error('The file must smaller than 5 Mb!')
          if(isLt5M && isCss) {
            const reader = new FileReader()
            reader.addEventListener("load", () => {
                style.value = reader.result as string
                store.commit('updateStyle', style)
            }, false)
            if (file) {
                reader.readAsText(file)
            }
            return isCss && isLt5M
        }
      }
      
      const tmpDataDimInput = {dimension: '', value: 1}
      // dimensional configuration
      const dataDimConfigState = reactive({
          inputVisible: new Array(dataAttrTable.value.length).fill(false),
          inputValue: new Array(dataAttrTable.value.length).fill(''),
          dataFormVisible: {
            scale: false,
            perturb: false,
            sample: false
          },
          dataFormInput: {
            scale: Object.assign({}, tmpDataDimInput),
            perturb: Object.assign({}, tmpDataDimInput),
            sample: 1
          }
        })

    
      const showInputValue = (k:number|string) => {
        if (k == 'scale') {
          dataDimConfigState.dataFormVisible.scale = true
          return
        }
        if (k == 'sample') {
          dataDimConfigState.dataFormVisible.sample = true
          return
        }
        if (k == 'perturb') {
          dataDimConfigState.dataFormVisible.perturb = true
          return
        }
        const row = k as number
        dataDimConfigState.inputVisible[row] = true
      }
      const handleInputStart = (k:number) => {
        dataDimConfigState.inputValue[k] = ''
      }
      const handleInputConfirm = (k: number | string) => {
        if (k == 'scale') {
           const tmp = dataDimConfigState.dataFormInput[k]
          if (typeof(tmp.value) != 'number' || tmp.value < 0 || tmp.dimension == '') {
            message.warn('Please check the value again.')
            return
          }
          config.value.dataConfig.scale.push(new BasicDataConfig('scale', tmp.value, tmp.dimension))
          dataDimConfigState.dataFormVisible.scale = false
          dataDimConfigState.dataFormInput[k] = Object.assign({}, tmpDataDimInput)
          return
        }
        if (k == 'perturb') {
          const tmp = dataDimConfigState.dataFormInput[k]
          if (typeof(tmp.value) != 'number' || tmp.value < 0 || tmp.dimension == '') {
            message.warn('Please check the value again.')
            dataDimConfigState.dataFormInput[k] = Object.assign({}, tmpDataDimInput)
            return
          }
          config.value.dataConfig.perturb.push(new BasicDataConfig('perturb', tmp.value, tmp.dimension))
          dataDimConfigState.dataFormVisible.perturb = false
          return
        }
        if (k == 'sample') {
          const tmp = dataDimConfigState.dataFormInput[k]
          if (typeof(tmp) != 'number' || tmp <= 0) {
            message.warn('Please check the value again.')
            return
          }
          config.value.dataConfig.sample.push(tmp)
          dataDimConfigState.dataFormVisible.sample = false
          dataDimConfigState.dataFormInput[k] = 0
          return
        }
        const row = k as number
        let newVal = dataDimConfigState.inputValue[row]
        if(dataAttrTable.value[row].type === 'number') newVal = parseFloat(newVal)
        const field = dataAttrTable.value[row].field
        dataAttrTable.value[row].insertValues.push(dataDimConfigState.inputValue[row])
        config.value.dataConfig.extreme.push(new BasicDataConfig('extreme', newVal, field))
        dataDimConfigState.inputVisible[row] = false
        dataDimConfigState.inputValue[row] = ''
      }
      const handleCloseInput = (k: number|string, idx: number) => {
        if (k == 'scale') {
          config.value.dataConfig.scale.splice(idx, 1)
          return
        }
        if (k == 'perturb') {
          config.value.dataConfig.perturb.splice(idx, 1)
          return
        }
        if (k == 'sample') {
          config.value.dataConfig.sample.splice(idx, 1)
          return
        }
        dataAttrTable.value[k].insertValues.splice(idx, 1)
        const row = k as number
        let newVal = dataDimConfigState.inputValue[row]
        if(dataAttrTable.value[row].type === 'number') newVal = parseFloat(newVal)
        const field = dataAttrTable.value[row].field
        let cnt = -1
        let i = 0
        for (; i < config.value.dataConfig.extreme.length; i++) {
          const val = config.value.dataConfig.extreme[i]
          if (val.dimension == field) cnt ++
          if (cnt == idx) break
        }
        config.value.dataConfig.extreme.splice(i, 1)
        

      }
      const dataTestState = reactive({
        indeterminate: true,
        checkAllDataTest: false,
      })
      const getNewInteractionForm = () =>  {
        return {
          tag: '',
          keep: false,
          times: undefined,
          sequence: [{
          event: undefined,
          element: ''
        }] as {event?: string, element: string}[]
        } as Record<string, any>
      }
      let customInteractConfigCnt = 0
      let interactConfigState = reactive({
        needAddInteraction: false,
        interactionForm: getNewInteractionForm(),
      })
      const addInteractionConfig = function () {
        interactConfigState.needAddInteraction = true
      }

      const addInteractionSeq = function () {
        interactConfigState.interactionForm.sequence.push({
          event: undefined,
          element: ''
        })
      }

      const removeInteractionSeq = function (idx: number) {
        const res = interactConfigState.interactionForm.sequence.splice(idx, 1)
      }

      const checkNewInteractConfig = function (): boolean {
        let flag = true
        const val = interactConfigState.interactionForm
        if (val.tag == '') val.tag = `custom-${customInteractConfigCnt++}`
        if (typeof val.times == 'undefined') val.times = 1
        for (const seq of val.sequence) {
          if (typeof seq.event == 'undefined') flag = false
        }
        return flag
      }

      const confirmAddInteraction = function () {
        if (!checkNewInteractConfig()) {
          message.warn('Please at least specify the event type for each sequence.')
          return
        }
        config.value.interactionConfig.push(new InteractionConfig(interactConfigState.interactionForm))
        interactConfigState.needAddInteraction = false
        interactConfigState.interactionForm = getNewInteractionForm()
      }

      const handleDeleteInteractionConfig = function (idx: number, cfg: InteractionConfigType) {
        config.value.interactionConfig.splice(idx, 1)
      }
     
      // device configuration
      const deviceColumns = reactive([{
          title: 'tag',
          dataIndex: 'label',
          slots: { customRender: 'updateDeviceTag'}
      }, {
          title: 'width',
          dataIndex: 'width',
          slots: { customRender: 'width' }
      }, {
          title: 'height',
          dataIndex: 'height',
          slots: { customRender: 'height' }
      }, {
          title: 'shape',
          slots: { customRender: 'previewDevice' }
      }, {
          title: 'delete',
          slots: { customRender: 'deleteDevice' }
      }])
      const deviceKeyCount = ref(0)
      let deviceDataSource = ref(config.value.deviceConfig)
      const editableDeviceData: UnwrapRef<Record<string, Partial<DeviceSettingType>>> = reactive({})
      const editDeviceSetting = (key: string, col: string) => {
        editableDeviceData[key] = {}
        if(col == 'width')
          editableDeviceData[key][col] = deviceDataSource.value.filter(item => key === item.key)[0].width
        if (col == 'height')
           editableDeviceData[key][col] = deviceDataSource.value.filter(item => key === item.key)[0].height    
        if (col == 'label')
          editableDeviceData[key]['label'] = deviceDataSource.value.filter(item => key === item.key)[0].label   
      }
      const onDeleteDeviceSetting = (key: string) => {
        if(config.value.deviceConfig.length === 1) {
           message.error('There must be at least one device config!')
            return
        } 
        config.value.deviceConfig = deviceDataSource.value.filter(item => item.key !== key)
        deviceDataSource.value = config.value.deviceConfig
      }
    
      const handleAddDeviceSetting = (width=500, height=500) => {
        deviceKeyCount.value += 1
        const newData = {
          key: deviceKeyCount.value.toString(),
          label: deviceKeyCount.value.toString(),
          width: width,
          height: height
        }
        config.value.deviceConfig.push(newData)
        deviceDataSource.value = config.value.deviceConfig
      }
      const saveDeviceSetting = (key: string, col: string) => {
        const obj = deviceDataSource.value.filter(item => key === item.key)[0]
        if (col == 'width') {
          obj.width = Number(editableDeviceData[key]['width'])!
          delete editableDeviceData[key]['width']
        }
        if (col == 'height') {
          obj.height = Number(editableDeviceData[key]['height'])!
          delete editableDeviceData[key]['height']
        }
        if (col == 'label') {
          obj.label = editableDeviceData[key]['label']!
          delete editableDeviceData[key]['label']
        }
      }

      const detectColumns = reactive([{
        title: "On",
        slots: { customRender: 'detectCheckList' },
        width: 40,
        align: "center"
      }, {
        title: "type",
        dataIndex: "name"
      }, {
        title: 'threshold',
        slots: { customRender: 'setDetectThresholdValue',
        title: 'testTitle'}
      }, {
        title: 'highlight',
        slots: { customRender: 'highlightColor' }
      }])
      const detectDataSource = ref(config.value.errorDetection.uiTable)
      const updateDetectConfig = (record: any) => {
        if (record._key == 'timeout') {
          if (record.isOn == false) {
            record.threshold = 99999999
          }
        }
        config.value.errorDetection.update(record._key, {
          threshold: parseInt(record.threshold),
          isOn: record.isOn
        })
      }

      const needInputUndesiredValue = ref(false)
      const tmpUndesiredInputVal = ref('')
      const confirmAddListVal = function (type: string, key='') {
        if (type == 'undesired') {
          config.value.errorDetection.invalidValue.details.push(tmpUndesiredInputVal.value)
          tmpUndesiredInputVal.value = ''
          needInputUndesiredValue.value = false
        }
      }
      const inputStartListVal = function (type: string, key='') {
        if (type == 'undesired') {
          tmpUndesiredInputVal.value = ''

        }
      }
      const removeListVal = function(type: string, index: number, key='') {
        if(type == 'undesired') {
          config.value.errorDetection.invalidValue.details.splice(index, 1)
        }
      }
      /** Update the UI according to the uploaded configuration */
      const updateConfigUI = function() {
        deviceDataSource.value = config.value.deviceConfig  // device setting
        detectDataSource.value = config.value.errorDetection.uiTable


      }

      const performCodeAnalysis = () => {
        const analyzer = new Analyzer(store.state.code)
        const bindings = analyzer.getAllEventBindings()
        store.commit('updateEventBindings', bindings)
      }



      const runTest = () => {
        store.commit('updateConfig', config)
        store.commit('updateStyle', style)
        store.commit('updateDataset', dataset.value)
        const code = store.state.updateCode
        config.value['dependencies']['d3'] = store.state.version
        const runner = new Runner(code, dataset.value, config.value, style.value, store.state.bindings)
        runner.exec('test-svg-zone', store).then(res => {
           store.commit('updateTestResult', res)
        })
      }
      onMounted(() => {
          // store.commit('updateDataset', dataset.value)
      })
      return {
          dataColumns,
          dataAttrTable,
          tableHeight,
          datasetFileList,
          dataset,
          beforeUploadConfig,
          backendConfigHandlerUrl,
          configFileList,
          config,
          getVisibleConfig,
          updateConfigRaw,
          bindings,
          beforeUploadStyle,
          styleFileList,
          style,
          ...toRefs(dataDimConfigState),
          showInputValue,
          handleInputStart,
          handleInputConfirm,
          handleCloseInput,
          handleConfigEditorUpdate,
          handleStyleEditorUpdate,
          ...toRefs(dataTestState),
          ...toRefs(interactConfigState),
          hasInteraction,
          interactConfigs,
          addInteractionConfig,
          addInteractionSeq,
          removeInteractionSeq,
          handleDeleteInteractionConfig,
          interactActiveKey,
          confirmAddInteraction,
          deviceColumns,
          deviceDataSource,
          editableDeviceData,
          handleAddDeviceSetting,
          onDeleteDeviceSetting,
          editDeviceSetting,
          saveDeviceSetting,
          hasData,
          activeKeyForDataConfigTab,
          backendDatasetHandlerUrl,
          beforeUploadDataset,
          handleUploadDatasetFileChange,
          detectDataSource,
          detectColumns,
          updateDetectConfig,
          needInputUndesiredValue,
          tmpUndesiredInputVal,
          confirmAddListVal,
          inputStartListVal,
          removeListVal,
          performCodeAnalysis,
          runTest
      }
    },
    methods: {
      exportConfig() {
        const jsonStr = JSON.stringify(this.config, null, '  ')
        const filename = `config-${new Date().toISOString()}.json`
        const element = document.createElement('a')
        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(jsonStr))
        element.setAttribute('download', filename)
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
})
</script>
<style lang="scss">
$fillRectColor: #e2f5ff;
.card-container {
  .ant-layout-content {
    min-height: 40vh !important;
    margin: 0 !important;
  }
}
.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;

  background: #f5f5f5;
}
#config-panel {
  height: 100%;
  .card-container {
    height: 100%;
    overflow-y: scroll;
  }
}
.ant-input {
  min-width: 90px !important;

}

.ant-layout-footer {
  position: sticky;
  bottom: 0px;
  
  button {
    margin-left: 16px;
  }
}

.dist-svg {
  rect {
    stroke: none;
    fill: $fillRectColor;
  }
}
.preview-device-svg rect {
  stroke: none;
  fill: $fillRectColor;
}
.ant-col-6 {
  text-align: center;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 36px !important;
}
.ant-collapse-content > .ant-collapse-content-box {
    padding: 8px 16px !important;
}
.available-events-div {
  margin-bottom: 1vh;
  padding-left: 16px;
}
.full-width {
  width: 100%;
}
.section-wrapper {
  margin-bottom: 2vh;
}
.form-wrapper {
  background-color: white;
  border-radius: .1em;
  padding: 8px 16px;
  border: rgba(0, 0, 0, 0.15) 1px solid;
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  .center-wrapper {
    width: 100%;
    text-align: center;
  }
  .ant-divider {
    margin-top: 0 !important;
  }

}
</style>
